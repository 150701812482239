import React from "react";

const Phone = (props) => {
  return (
    <svg
      height="21"
      width="13"
      fill="none"
      viewBox="0 0 13 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.612 18.9c0-.36-.128-.67-.385-.927a1.264 1.264 0 0 0-.927-.386c-.36 0-.67.129-.927.386a1.264 1.264 0 0 0-.386.927c0 .36.129.67.386.927s.566.385.927.385c.36 0 .67-.128.927-.385s.385-.566.385-.927Zm3.413-2.625V4.725a.505.505 0 0 0-.156-.37.504.504 0 0 0-.369-.155H2.1a.505.505 0 0 0-.37.156.505.505 0 0 0-.155.369v11.55c0 .142.052.265.156.37a.505.505 0 0 0 .369.155h8.4a.504.504 0 0 0 .37-.156.504.504 0 0 0 .155-.369ZM7.875 2.363c0-.175-.088-.263-.263-.263H4.987c-.175 0-.262.087-.262.262 0 .175.088.263.263.263h2.624c.175 0 .263-.087.263-.263ZM12.6 2.1v16.8c0 .569-.208 1.06-.623 1.477A2.018 2.018 0 0 1 10.5 21H2.1c-.569 0-1.06-.208-1.477-.623A2.018 2.018 0 0 1 0 18.9V2.1C0 1.531.208 1.04.623.623A2.018 2.018 0 0 1 2.1 0h8.4c.569 0 1.06.208 1.477.623.415.416.623.908.623 1.477Z"
        fill="#ADB0BA"
      />
    </svg>
  );
};

export default Phone;
