import React from "react";

const CalenderScroll = () => {
  return (
    <svg
      width="41"
      height="36"
      viewBox="0 0 41 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.3472 31.4761V31.4763C40.346 32.4509 39.9581 33.3853 39.2685 34.0741C38.5789 34.7629 37.6441 35.1499 36.6695 35.1499H36.5614L36.5613 35.1497H4.52781C3.55244 35.1497 2.61707 34.7622 1.92729 34.0724C1.23749 33.3828 0.85 32.4472 0.85 31.4719V7.88718C0.85 6.91183 1.23742 5.97626 1.92727 5.28665L40.3472 31.4761ZM40.3472 31.4761V7.88718C40.3472 6.91182 39.9597 5.97626 39.27 5.28665C38.5802 4.59681 37.6448 4.20937 36.6694 4.20937H4.52781C3.55243 4.20937 2.61707 4.59686 1.92729 5.28663L40.3472 31.4761ZM3.52512 6.88454C3.52514 6.88452 3.52515 6.8845 3.52517 6.88449C3.79111 6.61859 4.15183 6.46932 4.52781 6.46932H36.6694C37.0454 6.46932 37.4061 6.61859 37.6721 6.88449L3.52512 6.88454ZM3.52512 6.88454C3.25939 7.15052 3.10992 7.51127 3.10992 7.88721V31.4762C3.10992 31.8521 3.25941 32.2129 3.52513 32.4786C3.7911 32.7446 4.15185 32.894 4.52781 32.894H36.6694C37.0454 32.894 37.4061 32.7446 37.6721 32.4786C37.9378 32.2129 38.0873 31.8521 38.0873 31.4762V7.88721C38.0873 7.51129 37.9378 7.15053 37.6721 6.88453L3.52512 6.88454Z"
        fill="#FCB615"
        stroke="#FCB615"
        strokeWidth="0.3"
      />
      <path
        d="M7.18136 7.86456C6.88159 7.86456 6.5942 7.74532 6.38231 7.53342C6.17065 7.32176 6.05142 7.03436 6.05142 6.73462V1.98004C6.05142 1.57638 6.2669 1.20339 6.61635 1.00157L7.18136 7.86456ZM7.18136 7.86456H7.18139C7.48112 7.86456 7.76849 7.74534 7.98036 7.5335L7.18136 7.86456Z"
        fill="#FCB615"
        stroke="#FCB615"
        strokeWidth="0.3"
      />
      <path
        d="M32.5272 7.53349C32.3151 7.32174 32.1962 7.03428 32.1962 6.73462V1.98004C32.1962 1.5764 32.4117 1.20342 32.7611 1.00159L32.5272 7.53349ZM32.5272 7.53349C32.739 7.74534 33.0264 7.86456 33.3261 7.86456H33.3262C33.6259 7.86456 33.9133 7.74531 34.1252 7.53343C34.3372 7.32168 34.4561 7.03425 34.4561 6.73462V1.98004C34.4561 1.57636 34.2406 1.2034 33.8912 1.00159L32.5272 7.53349ZM33.8912 1.00155C33.5416 0.799487 33.1107 0.79948 32.7612 1.00155L33.8912 1.00155Z"
        fill="#FCB615"
        stroke="#FCB615"
        strokeWidth="0.3"
      />
      <path
        d="M1.08777 11.8731L1.0878 11.8731C1.28965 11.5235 1.66293 11.3084 2.06647 11.3084H39.1307C39.5343 11.3084 39.9076 11.5235 40.1094 11.8731L40.1094 11.8731C40.3112 12.2228 40.3112 12.6535 40.1094 13.0031C40.1094 13.0031 40.1094 13.0031 40.1094 13.0031L39.9795 12.9281C39.8045 13.2314 39.4807 13.4181 39.1307 13.4181H2.06647C1.71642 13.4181 1.39267 13.2314 1.21769 12.9281L1.08777 11.8731ZM1.08777 11.8731C0.885987 12.2228 0.885959 12.6536 1.0878 13.0031L1.08777 11.8731Z"
        fill="#FCB615"
        stroke="#FCB615"
        strokeWidth="0.3"
      />
      <path
        d="M20.6773 14.2416C20.4856 14.2416 20.2989 14.2901 20.1334 14.3801L14.6679 20.7236L14.5244 20.7672C14.5244 20.7673 14.5244 20.7673 14.5244 20.7674C14.6347 21.1297 14.9191 21.4124 15.2819 21.5207C15.6446 21.629 16.0375 21.5484 16.3282 21.3061L16.3286 21.3065L16.3382 21.2969L20.6773 16.962L25.0149 21.2957C25.0151 21.2959 25.0153 21.2961 25.0156 21.2963C25.1902 21.4747 25.4186 21.5882 25.6632 21.621L25.6634 21.6401L25.8134 21.6389C26.1118 21.6365 26.3974 21.5165 26.6081 21.305L26.6081 21.3049C26.8188 21.0931 26.9375 20.8069 26.9383 20.5082C26.9391 20.2097 26.8221 19.9228 26.6128 19.7099L26.6119 19.709L21.4772 14.5704L21.4772 14.5704L21.4764 14.5697C21.2636 14.3596 20.9765 14.2416 20.6773 14.2416Z"
        fill="#FCB615"
        stroke="#FCB615"
        strokeWidth="0.3"
      />
      <path
        d="M20.6772 31.2675L20.6749 31.4143C20.6754 31.4143 20.6759 31.4143 20.6764 31.4143C20.9806 31.4186 21.2736 31.2996 21.4888 31.0844L21.489 31.0842L26.6119 25.9454L26.6121 25.9456L26.6176 25.9394C26.8765 25.6496 26.9684 25.2471 26.8609 24.8738C26.7534 24.5002 26.4614 24.2081 26.0878 24.1006L26.0463 24.2448L26.0878 24.1006C25.7143 23.9932 25.3117 24.085 25.0221 24.3441L25.0219 24.3439L25.016 24.3498L20.6772 28.6886L16.3381 24.3498L16.3383 24.3496L16.3321 24.3441C16.0424 24.085 15.6398 23.9932 15.2664 24.1006C14.8927 24.2081 14.601 24.5003 14.4932 24.8737L14.4932 24.8738C14.3858 25.2471 14.4776 25.6496 14.7366 25.9394L14.7364 25.9396L14.7423 25.9455L19.8771 31.0844L19.8772 31.0844L20.6772 31.2775V31.4143H20.6772V31.2675ZM20.6772 31.2675V31.2643V31.2675Z"
        fill="#FCB615"
        stroke="#FCB615"
        strokeWidth="0.3"
      />
    </svg>
  );
};

export default CalenderScroll;
