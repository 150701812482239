import React from "react";
import AttachmentIcon from "../../../assests/JsxSvgs/AttachmentIcon";
import CalenderScroll from "../../../assests/JsxSvgs/CalenderScroll";
import CashoutIcon from "../../../assests/JsxSvgs/CashoutIcon";
import CoinsIcons from "../../../assests/JsxSvgs/coinsIcons";
import DollarBonus from "../../../assests/JsxSvgs/DollarBonus";
import DollarCheck from "../../../assests/JsxSvgs/DollarCheck";
import GlobeIcon from "../../../assests/JsxSvgs/GlobeIcon";
import PercentageIcon from "../../../assests/JsxSvgs/PercentageIcon";
import TimerIcon from "../../../assests/JsxSvgs/TimerIcon";
import "./InfoPage.css";

const InfoPage = () => {
  return (
    <div className="info_page_root">
      <div className="flex justify-between align-center info_page_item">
        <div className="flex align-center">
          <div className="icon_cont info_icon_cont bg_blue_light">
            <GlobeIcon />
          </div>
          <p className="text">Redeem Web</p>
        </div>
        <div className="icon_cont">
          <AttachmentIcon />
        </div>
      </div>
      <div className="flex justify-between align-center info_page_item">
        <div className="flex align-center">
          <div className="icon_cont info_icon_cont bg_sky_blue_light">
            <PercentageIcon />
          </div>
          <div className="flex flex-column">
            <p className="text">Cash Rebate</p>
            <p className="text_secondary">5%</p>
          </div>
        </div>
      </div>
      <div className="flex justify-between align-center info_page_item">
        <div className="flex align-center">
          <div className="icon_cont info_icon_cont bg_purple_light">
            <DollarBonus />
          </div>
          <div className="flex flex-column">
            <p className="text">Points Collection</p>
            <p className="text_secondary">0.002%</p>
          </div>
        </div>
      </div>
      <div className="flex justify-between align-center info_page_item border_none">
        <div className="flex align-center">
          <div className="icon_cont info_icon_cont bg_blue_light">
            <CoinsIcons />
          </div>
          <div className="flex flex-column">
            <p className="text">Annual Fee</p>
            <p className="text_secondary">800 RM</p>
          </div>
        </div>
      </div>
      <p className="color_yellow mb-20">*Subsequent Cards are free of charge</p>
      <div className="flex justify-between align-center info_page_item ">
        <div className="flex align-center">
          <div className="icon_cont info_icon_cont bg_yellow_light">
            <TimerIcon />
          </div>
          <div className="flex flex-column">
            <p className="text">Late Charge</p>
            <p className="text_secondary">800 RM</p>
          </div>
        </div>
      </div>
      <div className="flex justify-between align-center info_page_item ">
        <div className="flex align-center">
          <div className="icon_cont info_icon_cont bg_purple_light">
            <CashoutIcon />
          </div>
          <div className="flex flex-column">
            <p className="text">Can Cashout</p>
            <p className="text_secondary">
              Yes, 20% Per Annum Each Transaction RM 200
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-between align-center info_page_item ">
        <div className="flex align-center">
          <div className="icon_cont info_icon_cont bg_green_light">
            <DollarCheck />
          </div>
          <div className="flex flex-column">
            <p className="text">Minimum Income</p>
            <p className="text_secondary">RM 150,000</p>
          </div>
        </div>
      </div>
      <div className="flex justify-between align-center info_page_item border_none ">
        <div className="flex align-center">
          <div className="icon_cont info_icon_cont bg_yellow_light">
            <CalenderScroll />
          </div>
          <div className="flex flex-column">
            <p className="text">Minimum Age</p>
            <p className="text_secondary">21</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPage;
