import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CardDetails from "../Pages/CardDetails/CardDetails";
import CardList from "../Pages/CardList/CardList";
import Dashboard from "../Pages/Dashboard/Dashboard";
import MyCards from "../Pages/MyCards/MyCards";
import Notifications from "../Pages/Notifications/Notifications";
import Restaurant from "../Pages/Restaurant/Restaurant";

const UserRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/cardlist" element={<CardList />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/mycards" element={<MyCards />} />
      <Route path="/card-details/:id" element={<CardDetails />} />
      <Route path="/restaurant/:id" element={<Restaurant />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default UserRoutes;
