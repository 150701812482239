import React from "react";
import "./NewsLetters.css";
import newLetterBanner1 from "../../../assests/Images/banners/news_letter_1.png";
import pyramidIcon from "../../../assests/Images/pyramid.png";

import newLetterBanner2 from "../../../assests/Images/banners/news_letter_2.png";
import { useSelector } from "react-redux";
import NewsLetter from "./NewsLetter";

const NewsLetters = () => {
  const { isLoading: newsLoading, news } = useSelector((store) => store?.news);

  return (
    <div>
      <p className="section_heading mb-20">News Letter</p>
      <div className="new_letter_cards_cont">
        {news?.map((each) => (
          <NewsLetter {...each} />
        ))}

        {/* <NewsLetter
          image={newLetterBanner1}
          name=" Apple Event on September 14 Announced"
          created_at="5 months ago"
          postedBy={pyramidIcon}
          postedByName="Public Bank"
        />
        <NewsLetter
          image={newLetterBanner2}
          name=" Apple Event on September 14 Announced"
          created_at="5 months ago"
          postedBy={pyramidIcon}
          postedByName="Public Bank"
        /> 
        */}
      </div>
    </div>
  );
};

export default NewsLetters;
