import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/Modal/ModalHeader";
import ModalBody from "../../../components/Modal/ModalBody";

const CreateNoteModal = ({ show, closer, isEdit, notes, setNotes, data }) => {
  const [values, setValues] = useState({});
  const inputChangeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();
    if (!isEdit) {
      let id = Math.floor(Math.random() * 99);
      let created_at = new Date().toISOString().slice(0, 10);
      let newNote = { ...values, id, created_at };
      setNotes((notes) => {
        let update = [newNote, ...notes];
        return update;
      });
    } else {
      let updatedNotes = notes.map((note) => {
        if (note.id == values.id) {
          note = { ...note, ...values };
        }
        return note;
      });
      setNotes(updatedNotes);
    }

    closer();
  };
  useEffect(() => {
    if (isEdit) {
      setValues({ ...data });
    }
  }, [isEdit]);
  return (
    <Modal className="create_note_modal_cont" show={show} closeModal={closer}>
      <ModalHeader closeModal={closer} className="" />
      <ModalBody className="">
        <form className="flex flex-column" onSubmit={formSubmitHandler}>
          <input
            placeholder="Title"
            onChange={inputChangeHandler}
            type="text"
            className="create_note_input"
            name="title"
            value={values.title || ""}
          />
          <textarea
            placeholder="Your title here"
            onChange={inputChangeHandler}
            type="text"
            rows={"auto"}
            className="create_note_input "
            name="note"
            value={values.note || ""}
          />
          <div className="flex gap-15 align-center">
            <button disabled={!values.title} className="btn-primary btn">
              {isEdit ? "Update" : "Save"}
            </button>
            <button onClick={closer} className="btn-secondary btn">
              Cancel
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default CreateNoteModal;
