import React from "react";

const GlobeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43.976"
      height="43.975"
      viewBox="0 0 43.976 43.975"
    >
      <path
        id="Path_13"
        data-name="Path 13"
        d="M37.535,6.44a21.988,21.988,0,1,0-31.1,31.1,21.988,21.988,0,0,0,31.1-31.1Zm.5,26.033a26.208,26.208,0,0,0-6.018-3.127,42.965,42.965,0,0,0,.6-6.563h8.53a19.055,19.055,0,0,1-3.111,9.691ZM2.83,22.782h8.531a42.978,42.978,0,0,0,.6,6.561A26.23,26.23,0,0,0,5.94,32.47,19.055,19.055,0,0,1,2.83,22.783ZM6.4,10.834a26.373,26.373,0,0,0,5.783,2.891,42.471,42.471,0,0,0-.773,6.245H2.919A19.062,19.062,0,0,1,6.4,10.833Zm8.487,3.708a29.481,29.481,0,0,0,7.1.866,29.442,29.442,0,0,0,7.1-.867,39.693,39.693,0,0,1,.661,5.429H14.226a39.639,39.639,0,0,1,.66-5.427Zm-.714,8.241H29.8a40.235,40.235,0,0,1-.5,5.721,29.573,29.573,0,0,0-14.639,0,40.409,40.409,0,0,1-.495-5.719ZM32.567,19.97a42.408,42.408,0,0,0-.773-6.247,26.4,26.4,0,0,0,5.781-2.891,19.065,19.065,0,0,1,3.483,9.138ZM35.76,8.659a23.542,23.542,0,0,1-4.621,2.311,35.762,35.762,0,0,0-2.753-7.059A19.236,19.236,0,0,1,35.76,8.659ZM24.624,2.995a1.444,1.444,0,0,0,.1.182A31.691,31.691,0,0,1,28.453,11.8a26.651,26.651,0,0,1-6.467.794,26.647,26.647,0,0,1-6.462-.793,31.7,31.7,0,0,1,3.733-8.625,1.421,1.421,0,0,0,.1-.182,19.2,19.2,0,0,1,5.271,0Zm-9.033.917a35.719,35.719,0,0,0-2.753,7.06A23.616,23.616,0,0,1,8.215,8.661,19.228,19.228,0,0,1,15.59,3.911Zm-7.939,30.8a23.334,23.334,0,0,1,4.881-2.575A36.428,36.428,0,0,0,15.4,40,19.254,19.254,0,0,1,7.652,34.707Zm11.514,6.248a32.328,32.328,0,0,1-3.951-9.691,26.779,26.779,0,0,1,13.546,0,32.349,32.349,0,0,1-3.951,9.689,19.232,19.232,0,0,1-5.644,0ZM28.576,40a36.439,36.439,0,0,0,2.867-7.863,23.349,23.349,0,0,1,4.881,2.574A19.246,19.246,0,0,1,28.576,40Z"
        fill="#2565ff"
      />
    </svg>
  );
};

export default GlobeIcon;
