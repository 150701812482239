import './App.css';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import MobileWidthLayout from './components/MobileWidthLoyout/MobileWidthLayout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from './redux/user/userSlice';
import AuthRoutes from './Routes/AuthRoutes';
import UserRoutes from './Routes/UserRoutes';

function App() {
  const { user } = useSelector(data => data)
  let isLoggedin = user?.user;
  const dispatch = useDispatch()
  const userDetails = localStorage.getItem('userDetails')
  if (userDetails) {
    if (!isLoggedin) {
      isLoggedin = true
      dispatch(setUserInfo(JSON.parse(userDetails)))
    }
  }

  console.log(user, 'some user')





  return (
    < >
      <ToastContainer />
      <div className="App">
        <MobileWidthLayout >
          <Router>
            {
              isLoggedin ? <UserRoutes /> : <AuthRoutes />
            }

          </Router>
        </MobileWidthLayout>

      </div>
    </>
  );
}

export default App;
