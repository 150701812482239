import React from "react";
import "./Modal.css";

const Modal = (props) => {
  return (
    <>
      {props.show && (
        <div className="modal-root-cont">
          <div className="root-modal-overlay" onClick={props.closeModal}></div>
          <div className={"root-modal-container " + props.className}>
            {props.children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
