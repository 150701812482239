import React from "react";

const LogoutIcon = () => {
  return (
    <svg
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.4296 19.4453H21.37C21.2294 19.4453 21.0976 19.5069 21.0097 19.6153C20.8046 19.8643 20.5849 20.1045 20.3534 20.333C19.4068 21.2806 18.2856 22.0357 17.0517 22.5567C15.7733 23.0966 14.3993 23.3736 13.0116 23.3711C11.6083 23.3711 10.2489 23.0957 8.97159 22.5567C7.73769 22.0357 6.61644 21.2806 5.66983 20.333C4.72154 19.3887 3.96539 18.2694 3.44328 17.0371C2.90128 15.7598 2.62882 14.4033 2.62882 13C2.62882 11.5967 2.90421 10.2403 3.44328 8.96291C3.96476 7.72951 4.71476 6.61916 5.66983 5.66701C6.62491 4.71486 7.73526 3.96486 8.97159 3.44337C10.2489 2.90431 11.6083 2.62892 13.0116 2.62892C14.4149 2.62892 15.7743 2.90138 17.0517 3.44337C18.288 3.96486 19.3983 4.71486 20.3534 5.66701C20.5849 5.89845 20.8017 6.13869 21.0097 6.38478C21.0976 6.49318 21.2323 6.5547 21.37 6.5547H23.4296C23.6141 6.5547 23.7284 6.34963 23.6259 6.19435C21.3788 2.70216 17.4472 0.390639 12.9794 0.402358C5.95987 0.419936 0.331951 6.11818 0.402263 13.1289C0.472575 20.0283 6.09171 25.5977 13.0116 25.5977C17.4677 25.5977 21.3817 23.2891 23.6259 19.8057C23.7255 19.6504 23.6141 19.4453 23.4296 19.4453ZM26.0341 12.8155L21.8768 9.5342C21.7216 9.41115 21.496 9.52248 21.496 9.71877V11.9453H12.2968C12.1679 11.9453 12.0624 12.0508 12.0624 12.1797V13.8203C12.0624 13.9492 12.1679 14.0547 12.2968 14.0547H21.496V16.2813C21.496 16.4776 21.7245 16.5889 21.8768 16.4658L26.0341 13.1846C26.0621 13.1627 26.0847 13.1347 26.1003 13.1027C26.1159 13.0707 26.124 13.0356 26.124 13C26.124 12.9644 26.1159 12.9293 26.1003 12.8974C26.0847 12.8654 26.0621 12.8374 26.0341 12.8155Z"
        fill="#EB353F"
      />
    </svg>
  );
};

export default LogoutIcon;
