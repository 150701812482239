import React from "react";

const PhoneIcon = () => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.7793 18.189C25.6442 17.8436 25.5598 17.2466 25.0761 17.2087L16.8819 15.9556C15.8849 15.9896 15.3748 17.5685 15.1132 18.3765V18.3767C13.1883 17.7444 11.4393 16.6945 10.0012 15.3075C8.56273 13.9205 7.47377 12.2337 6.81752 10.377C7.62118 10.1334 9.29115 9.62447 9.32434 8.66331L8.02503 0.757405C7.9861 0.517726 7.80551 0.322227 7.5631 0.257886C3.99905 -0.773472 -0.129159 1.36488 0.0290966 5.31027V5.30999C-0.163228 8.68598 0.600635 12.049 2.23882 15.0376C3.87728 18.0264 6.32798 20.5284 9.32825 22.2754C12.3287 24.0223 15.7654 24.9479 19.2698 24.9531C23.2735 25.4344 26.9489 22.1443 25.7797 18.1888L25.7793 18.189ZM23.4654 22.5049V22.5047C22.6251 23.3132 21.4708 23.7429 20.2854 23.6887C16.9023 23.8679 13.5352 23.1232 10.571 21.5399C7.60658 19.9566 5.16534 17.5987 3.5271 14.7372C1.88885 11.8757 1.12043 8.62614 1.30961 5.36234C1.15019 2.44117 3.99329 0.772758 6.82636 1.36316L7.99951 8.50561H7.9998C7.41366 8.92975 6.7188 9.19124 5.99009 9.26164C5.79004 9.26164 5.60143 9.35193 5.48038 9.50518C5.35903 9.65871 5.31925 9.8578 5.37219 10.0439C6.04218 12.3651 7.32204 14.4797 9.08791 16.1834C10.8538 17.8872 13.0455 19.1223 15.451 19.769C15.6353 19.8148 15.8308 19.7787 15.9844 19.6704C16.141 19.5625 16.2412 19.394 16.2586 19.209C16.3282 18.4933 16.6006 17.8099 17.0462 17.2339L24.4916 18.3718C24.7346 19.0848 24.7675 19.8491 24.5863 20.579C24.4052 21.3091 24.0171 21.9759 23.4656 22.5053L23.4654 22.5049Z"
        fill="white"
      />
    </svg>
  );
};

export default PhoneIcon;
