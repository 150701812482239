import React, { useState } from "react";
import "./NavBar.css";
import { IoNotificationsOutline } from "react-icons/io5";
import SearchBar from "../SearchBar/SearchBar";
import Hamicon from "../../assests/Images/hamicon.png";
import SideNav from "../SideNav/SideNav";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const [showSideNav, setShowSideNav] = useState(false);
  const openSideNav = () => {
    setShowSideNav(true);
  };
  const closeSideNav = () => {
    setShowSideNav(false);
  };
  const navigate = useNavigate();
  const notifyClickHandler = () => {
    navigate("/notifications");
  };
  return (
    <>
      {showSideNav && <SideNav closer={closeSideNav} />}
      <div className="bg_primary sticky_top_nav">
        <nav className="root_nav_bar">
          <div onClick={openSideNav} className="icon_cont">
            <img src={Hamicon} alt="" />
          </div>
          <p className="header_text">Today's Suggestions</p>
          <div className="relative icon_cont ">
            <div
              className="badge_dot badge_green"
              onClick={notifyClickHandler}
            ></div>
            <IoNotificationsOutline size="25" />
          </div>
        </nav>
        <SearchBar />
      </div>
    </>
  );
};

export default NavBar;
