import React from "react";

const CashoutIcon = () => {
  return (
    <svg
      width="44"
      height="41"
      viewBox="0 0 44 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6321 25.5512C24.5659 25.5512 25.3226 24.7941 25.3226 23.8607C25.3226 22.9272 24.5655 22.1702 23.6321 22.1702C22.6986 22.1702 21.9416 22.9272 21.9416 23.8607C21.9416 24.7945 21.1845 25.5512 20.2511 25.5512C19.3176 25.5512 18.5605 24.7941 18.5605 23.8607C18.5605 22.9272 19.3176 22.1702 20.2511 22.1702"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5605 23.8608H17.9324"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.9509 23.8608H25.3228"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.0943 23.8607C28.0943 27.2589 25.3396 30.0135 21.9418 30.0135C18.5439 30.0135 15.7893 27.2589 15.7893 23.8607C15.7893 20.4629 18.5439 17.7083 21.9418 17.7083C25.3396 17.7083 28.0943 20.4629 28.0943 23.8607"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5037 11.4756H31.3811V39.9095H12.5037V11.4756Z"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5037 11.4756H31.3811"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.0923 11.4756C27.1658 13.4481 29.1295 14.5564 31.3812 14.5564"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5037 14.5564C14.7553 14.5564 16.7191 13.4481 17.7926 11.4756"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6618 39.9122C18.6618 35.7034 15.9042 33.1641 12.502 33.1641"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.382 33.1643C27.9801 33.1643 25.2222 35.7036 25.2222 39.9124"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5037 14.5564C14.7553 14.5564 16.7191 13.4481 17.7926 11.4756"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5037 14.5564C14.7553 14.5564 16.7191 13.4481 17.7926 11.4756"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5037 14.5564C14.7553 14.5564 16.7191 13.4481 17.7926 11.4756"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5037 14.5564C14.7553 14.5564 16.7191 13.4481 17.7926 11.4756"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5037 14.5564C14.7553 14.5564 16.7191 13.4481 17.7926 11.4756"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5037 14.5564C14.7553 14.5564 16.7191 13.4481 17.7926 11.4756"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5037 14.5564C14.7553 14.5564 16.7191 13.4481 17.7926 11.4756"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5037 14.5564C14.7553 14.5564 16.7191 13.4481 17.7926 11.4756"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5037 14.5564C14.7553 14.5564 16.7191 13.4481 17.7926 11.4756"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5037 14.5564C14.7553 14.5564 16.7191 13.4481 17.7926 11.4756"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5037 14.5564C14.7553 14.5564 16.7191 13.4481 17.7926 11.4756"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.3802 21.946H41.0065C42.0452 21.946 42.8916 21.1083 42.8916 20.061V2.88537C42.8916 1.84672 42.0451 1.00034 41.0065 1.00034L2.8764 1C1.83775 1 1 1.84678 1 2.88539V20.061C1 21.1083 1.83774 21.946 2.8764 21.946H12.5028"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.88696 11.4756H34.9968"
        stroke="#A75AA0"
        strokeWidth="1.74549"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CashoutIcon;
