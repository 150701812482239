import React from "react";

const BankDetailsIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3334 1.00023H2.66689C1.74643 1.00023 1.00024 1.74641 1.00024 2.66688V19.3334C1.00024 20.2538 1.74643 21 2.66689 21H19.3334C20.2538 21 21 20.2538 21 19.3334V2.66688C21 1.74641 20.2538 1.00023 19.3334 1.00023Z"
        stroke="#555555"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M4.88892 4.88895H9.33331V9.33334H4.88892V4.88895Z"
        stroke="#555555"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M4.88892 17.111H17.111M12.6666 4.88895H17.111H12.6666ZM12.6666 8.77779H17.111H12.6666ZM4.88892 13.2222H17.111H4.88892Z"
        stroke="#555555"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BankDetailsIcon;
