import React from "react";

const CalenderTimerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.4"
      height="38.4"
      viewBox="0 0 38.4 38.4"
    >
      <g id="Group_11" data-name="Group 11" transform="translate(-0.8 -0.8)">
        <path
          id="Path_35"
          data-name="Path 35"
          d="M36,4H32.4V3.2A2.4,2.4,0,0,0,30,.8h-.8a2.4,2.4,0,0,0-2.4,2.4V4H16.4V3.2A2.4,2.4,0,0,0,14,.8h-.8a2.4,2.4,0,0,0-2.4,2.4V4H7.2A3.2,3.2,0,0,0,4,7.2V18.352a.8.8,0,1,0,1.6,0V13.6H28.8a.8.8,0,1,0,0-1.6H5.6V7.2A1.6,1.6,0,0,1,7.2,5.6h3.6v.8a2.4,2.4,0,0,0,2.4,2.4H14a2.4,2.4,0,0,0,2.4-2.4V5.6H26.8v.8a2.4,2.4,0,0,0,2.4,2.4H30a2.4,2.4,0,0,0,2.4-2.4V5.6H36a1.6,1.6,0,0,1,1.6,1.6V12H34.016a.8.8,0,0,0,0,1.6H37.6V31.2A1.6,1.6,0,0,1,36,32.8H22.136a.8.8,0,1,0,0,1.6H36a3.2,3.2,0,0,0,3.2-3.2V7.2A3.2,3.2,0,0,0,36,4ZM14.8,6.4a.8.8,0,0,1-.8.8h-.8a.8.8,0,0,1-.8-.8V3.2a.8.8,0,0,1,.8-.8H14a.8.8,0,0,1,.8.8Zm16,0a.8.8,0,0,1-.8.8h-.8a.8.8,0,0,1-.8-.8V3.2a.8.8,0,0,1,.8-.8H30a.8.8,0,0,1,.8.8Z"
          fill="#7f35fd"
        />
        <path
          id="Path_36"
          data-name="Path 36"
          d="M10.4,20a9.6,9.6,0,1,0,6.788,2.812A9.6,9.6,0,0,0,10.4,20Zm0,17.6a8,8,0,1,1,5.657-2.343A8,8,0,0,1,10.4,37.6Z"
          fill="#7f35fd"
        />
        <path
          id="Path_37"
          data-name="Path 37"
          d="M14.4,28.8H11.2v-4a.8.8,0,0,0-1.6,0v4.8a.8.8,0,0,0,.8.8h4a.8.8,0,1,0,0-1.6Z"
          fill="#7f35fd"
        />
      </g>
    </svg>
  );
};

export default CalenderTimerIcon;
