import React from "react";
import "./Shops.css";
import { useSelector } from "react-redux";
import Dropdown from "../../../components/Dropdown/Dropdown";

const DropdownData = [
  {
    id: 1,
    label: "Popular",
  },
  {
    id: 2,
    label: "Random",
  },
  {
    id: 3,
    label: "Name",
  },
];

const Shops = () => {
  const { shops } = useSelector((store) => store?.shops);

  return (
    <div className="card shops m-20">
      <div className="flex justify-between align-center">
        <p className="section_heading">Shops</p>
        <Dropdown dropdownData={DropdownData} />
      </div>
      <div className="shop_logos mt-20">
        {shops?.map((each) => (
          <img
            src={`${process.env.REACT_APP_BASE_URL}/${each.image}`}
            alt=""
            className="shop_logo"
          />
        ))}
      </div>
    </div>
  );
};

export default Shops;
