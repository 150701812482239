import React from "react";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/Modal/ModalHeader";
import ModalBody from "../../../components/Modal/ModalBody";
import { transactionHistoryData } from "./SampleData";

const ViewAllCardsModal = ({ show, closer }) => {
  return (
    <Modal className="view_all_cards_modal" show={show} closeModal={closer}>
      <ModalHeader title="All Cards" closeModal={closer} className="" />
      <ModalBody className="">
        <div className="transactions mt-20">
          {transactionHistoryData.map((each) => (
            <div key={each.id} className="transaction">
              <div className="flex justify-between align-center">
                <div className="flex align-center">
                  <p className="transaction_order">{each.order}</p>
                  <div className="flex flex-column">
                    <p className="transaction_bank">{each.name}</p>
                    <p className="tra_card_no">
                      <span>Card : </span>
                      <span>{each.card}</span>
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <p className="tra_at mr-8">{each.date}</p>
                  {each.paid ? (
                    <p className="color_green ">Paid</p>
                  ) : (
                    <p className="color_red">Due</p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ViewAllCardsModal;
