import React, { useState } from "react";
import "./FilePage.css";
import "./FilePage.css";
import { FiPlus } from "react-icons/fi";
import FileNote from "./FileNote";
import { NOTES_DATA } from "./SampleData";
import CreateNoteModal from "./CreateNoteModal";

const FilePage = () => {
  const [notes, setNotes] = useState(NOTES_DATA);
  const [showCreate, setShowCreate] = useState(false);
  const [updateNote, setUpdateNote] = useState();
  const editClickHandler = (data) => {
    setUpdateNote(data);
  };
  const deleteClickHandler = (data) => {
    let filterData = notes.filter((each) => each.id != data.id);
    setNotes(filterData);
  };

  const closeCreateModal = () => {
    setShowCreate(false);
  };
  const closeUpdateModal = () => {
    setUpdateNote(null);
  };
  return (
    <>
      {showCreate && (
        <CreateNoteModal
          show={showCreate}
          notes={notes}
          setNotes={setNotes}
          closer={closeCreateModal}
        />
      )}
      {updateNote && (
        <CreateNoteModal
          show={updateNote}
          notes={notes}
          setNotes={setNotes}
          closer={closeUpdateModal}
          data={updateNote}
          isEdit
        />
      )}
      <div className="file_page_cont">
        <button onClick={() => setShowCreate(true)} className="create_new">
          Create new Note
          <span className="add_icon icon_cont">
            <FiPlus size={22} />
          </span>
        </button>
        <div className="notes_containers pt-20">
          {notes.map((note) => (
            <FileNote
              key={note.id}
              editHandler={editClickHandler}
              data={note}
              deleteHandler={deleteClickHandler}
            />
          ))}
          {!notes?.length && <p className="color_red">No data found</p>}
        </div>
      </div>
    </>
  );
};

export default FilePage;
