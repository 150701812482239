import React from "react";
import ManImage from "../../../assests/Images/woman.png";

const OtherUserMessages = () => {
  return (
    <div className="flex other_user">
      <div>
        <div className="messages">
          <p className="message">On touchngo website</p>
          <p className="message last">Yes you can.</p>
        </div>
        <p className="message_info">
          <span>Admin</span> . <span>12:02 PM</span>
        </p>
      </div>
      <div className="profile_img">
        <img src={ManImage} alt="" />
      </div>
    </div>
  );
};

export default OtherUserMessages;
