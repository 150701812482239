import React from "react";

const RectEdit = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.213"
      height="18.213"
      viewBox="0 0 18.213 18.213"
    >
      <g id="Group_10" data-name="Group 10" transform="translate(-0.88 -0.88)">
        <path
          id="Path_30"
          data-name="Path 30"
          d="M15.718.88a3.352,3.352,0,0,0-2.385.987L7.216,7.986A5.543,5.543,0,0,0,5.58,11.934v2.459H8.039a5.543,5.543,0,0,0,3.945-1.636l6.122-6.116A3.375,3.375,0,0,0,15.718.88Zm1.14,4.512-6.116,6.119a3.8,3.8,0,0,1-2.7,1.119h-.7v-.7a3.794,3.794,0,0,1,1.119-2.7l6.119-6.119a1.613,1.613,0,0,1,2.279,2.28Z"
          fill="#2565ff"
        />
        <path
          id="Path_31"
          data-name="Path 31"
          d="M4.111,19.093H14.686a3.231,3.231,0,0,0,3.231-3.231v-4.7H16.155v4.7a1.469,1.469,0,0,1-1.469,1.469H4.111a1.468,1.468,0,0,1-1.469-1.469V5.286A1.469,1.469,0,0,1,4.111,3.817h4.7V2.055h-4.7A3.231,3.231,0,0,0,.88,5.286V15.861a3.231,3.231,0,0,0,3.231,3.231Z"
          fill="#2565ff"
        />
      </g>
    </svg>
  );
};

export default RectEdit;
