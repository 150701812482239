import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";

const Dropdown = ({ dropdownData = [], onSelectCallback }) => {
  const [searchType, setSearchType] = useState(dropdownData[0]);
  const dropdownClickHandler = (each) => {
    setSearchType(each);
    onSelectCallback(each);
  };
  return (
    <div>
      <div className="relative">
        <p className="active_pill blue color_blue drop_menu_label">
          {searchType?.label}
          <span className="chevron_down icon_cont">
            <FiChevronDown size={20} className="color_blue" />
          </span>
        </p>
        <div className="drop_menu_cont absolute">
          <ul className="menu_list">
            {dropdownData?.map((each) => (
              <li
                onClick={() => dropdownClickHandler(each)}
                className={`menu_item ${
                  each.id == searchType.id ? "active" : ""
                }`}
              >
                {each.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
