import React from "react";
import "./Discover.css";
import discoverBanner1 from "../../../assests/Images/banners/discover_banner_1.png";
import discoverBanner2 from "../../../assests/Images/banners/discover_banner_2.png";

const Discover = () => {
  return (
    <div>
      <p className="section_heading mb-20">Discover</p>
      <div className="overflow-x">
        <div className=" cards_scroll gap-10">
          <div className="discover_card discover_card_1">
            <img src={discoverBanner1} alt="" />
          </div>
          <div className="discover_card discover_card_2">
            <img src={discoverBanner2} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discover;
