import React from "react";
import { ClipLoader, ScaleLoader } from "react-spinners";
import "./Loader.css";

const Loader = ({ loading = false, size = 70 }) => {
  return (
    loading && (
      <div className="loader-backdrop">
        <div className="loader-root">
          <ScaleLoader color={"#2565FF"} loading={loading} size={size} />
        </div>
      </div>
    )
  );
};

export default Loader;
