import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/Modal/ModalHeader";
import ModalBody from "../../../components/Modal/ModalBody";
import "./CreateCard.css";
const CreateCard = ({ show, closer, isEdit, data }) => {
  const [values, setValues] = useState({});
  const inputChangeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();
    if (!isEdit) {
    } else {
    }

    closer();
  };
  useEffect(() => {
    if (isEdit) {
      setValues({ ...data });
    }
  }, [isEdit]);
  return (
    <Modal className="create_note_modal_cont" show={show} closeModal={closer}>
      <ModalHeader closeModal={closer} className="" />
      <ModalBody className="create-card">
        <form className="flex flex-column" onSubmit={formSubmitHandler}>
          <input
            placeholder="Name"
            onChange={inputChangeHandler}
            type="text"
            className="create_note_input"
            name="name"
            value={values.name || ""}
          />
          <input
            placeholder="Info Url"
            onChange={inputChangeHandler}
            type="text"
            className="create_note_input"
            name="info_url"
            value={values.info_url || ""}
          />
          <input
            placeholder="Card Type Id"
            onChange={inputChangeHandler}
            type="text"
            className="create_note_input"
            name="card_type_id"
            value={values.card_type_id || ""}
          />
          <input
            placeholder="Bank Id"
            onChange={inputChangeHandler}
            type="text"
            className="create_note_input"
            name="bank_id"
            value={values.bank_id || ""}
          />
          <input
            placeholder="Description"
            onChange={inputChangeHandler}
            type="text"
            className="create_note_input"
            name="description"
            value={values.description || ""}
          />

          <div className="flex gap-15 align-center">
            <button disabled={!values.title} className="btn-primary btn">
              {isEdit ? "Update" : "Save"}
            </button>
            <button onClick={closer} className="btn-secondary btn">
              Cancel
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default CreateCard;
