import React, { useState } from "react";
import "./ShopsPage.css";
import shop1 from "../../../assests/Images/shops_square/shop_1.png";
import shop2 from "../../../assests/Images/shops_square/shop_2.png";
import shop3 from "../../../assests/Images/shops_square/shop_3.png";
import shop4 from "../../../assests/Images/shops_square/shop_4.png";
import shop5 from "../../../assests/Images/shops_square/shop_5.png";
import shop6 from "../../../assests/Images/shops_square/shop_6.png";

import { shopsData } from "./sampleData";
import ShopDetailModal from "./ShopDetailModal";

const ShopsPage = () => {
  const [currentShop, setCurrentShop] = useState();
  const clearCurrentShop = () => {
    setCurrentShop(null);
  };
  return (
    <>
      <div className="shop_page_cont">
        {currentShop && (
          <ShopDetailModal
            data={currentShop}
            clearCurrentShop={clearCurrentShop}
          />
        )}
        {shopsData.map((shop) => (
          <div
            key={shop.id}
            onClick={() => setCurrentShop(shop)}
            className="flex justify-between align-center info_page_item"
          >
            <div className="flex align-center">
              <p className="order">{shop.id}</p>
              <div className="icon_cont ">
                <img src={shop.image} alt="" />
              </div>
              <div className="flex flex-column align-start">
                <p className="text">{shop.name}</p>
                <p className={`tag color_${shop.color} bg_${shop.color}_light`}>
                  {shop.tag_name}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ShopsPage;
