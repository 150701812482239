export const NOTES_DATA = [
  {
    id: 1,
    title: "Grabcar",
    note: "Nunc scelerisque tincidunt elit. Vestibulum non mi ipsum. Cras pretium suscipit tellus sit amet aliquet. Vestibulum maximus lacinia massa non porttitor. Pellentesque vehicula est a lorem gravida bibendum. Proin tristique diam ut urna pharetra, ac rhoncus elit",
    created_at: "23 March 2022",
  },
  {
    id: 2,
    title: " lorem ipsum",
    note: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur quos cupiditate ab adipisci veritatis odio alias animi vitae, rerum accusantium placeat mollitia. Exercitationem corporis quia ab voluptatem cupiditate a ullam! ",
    created_at: "24 March 2022",
  },
];
