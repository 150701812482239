import React from "react";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/Modal/ModalHeader";
import ModalBody from "../../../components/Modal/ModalBody";

const ShopDetailModal = ({ data, clearCurrentShop }) => {
  return (
    <Modal show={data} closeModal={clearCurrentShop}>
      <ModalHeader
        closeModal={clearCurrentShop}
        className="some class names for custom styles"
      />
      <ModalBody className="some class names for custom styles">
        <div className="flex justify-between align-center ">
          <div className="flex align-center">
            <div className="icon_cont ">
              <img src={data.image} alt="" />
            </div>
            <div className="flex flex-column align-start">
              <p className="text fs_20">{data.name}</p>
              <p className={`tag color_${data.color} bg_${data.color}_light`}>
                {data.tag_name}
              </p>
            </div>
          </div>
        </div>
        <p className="shop_item_modal_desc">{data.description}</p>
      </ModalBody>
    </Modal>
  );
};

export default ShopDetailModal;
