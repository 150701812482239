import React from "react";
import { monthsAgoCalculator } from "../../components/utilities/reuseFunctions";

const NotificationItem = ({ image, title, description, date }) => {
  return (
    <div className="notification_item">
      <div className="flex  justify-between">
        <div className="flex align-center">
          <div className="image_cont">
            <img src={image} alt="" />
          </div>
          <div>
            <p className="text_light">{title}</p>
            <p className="description">{description}</p>
          </div>
        </div>
        <p className="time_ago">{monthsAgoCalculator(date, true)}</p>
      </div>
    </div>
  );
};

export default NotificationItem;
