import React from "react";

const NoteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <path
        id="Path_34"
        data-name="Path 34"
        d="M9.1.6A3.5,3.5,0,0,0,5.6,4.1V33.938A3.454,3.454,0,0,0,4.1,33.6a3.5,3.5,0,0,0,0,7H32.112a3.494,3.494,0,0,0,1.12-.188A3.487,3.487,0,0,0,35.6,37.1V7.262A3.5,3.5,0,1,0,37.1.6Zm0,2A1.5,1.5,0,0,0,7.6,4.1v33a3.487,3.487,0,0,1-.336,1.5H32.1a1.5,1.5,0,0,0,1.5-1.5V4.1a3.487,3.487,0,0,1,.338-1.5Zm-5,36a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,4.1,38.6Zm6.5-5h20a1,1,0,1,0,0-2h-20a1,1,0,1,0,0,2Zm0-6h14a1,1,0,1,0,0-2h-14a1,1,0,1,0,0,2Zm0-6h20a1,1,0,1,0,0-2h-20a1,1,0,1,0,0,2Zm0-6h14a1,1,0,1,0,0-2h-14a1,1,0,1,0,0,2Zm0-6h20a1,1,0,1,0,0-2h-20a1,1,0,1,0,0,2Zm26.5-7a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,37.1,2.6Z"
        transform="translate(-0.6 -0.6)"
        fill="#fcb615"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default NoteIcon;
