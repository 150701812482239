import React from "react";

const UserSvgIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clipPath">
          <rect
            id="Rectangle_3"
            data-name="Rectangle 3"
            width="24"
            height="24"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Group_5" data-name="Group 5" clipPath="url(#clipPath)">
        <path
          id="Path_11"
          data-name="Path 11"
          d="M12,4.094a4.4,4.4,0,1,0,3.1,1.288A4.393,4.393,0,0,0,12,4.094Zm0,7.028a2.639,2.639,0,1,1,1.863-.773A2.636,2.636,0,0,1,12,11.123Z"
          fill="#02af56"
        />
        <path
          id="Path_12"
          data-name="Path 12"
          d="M24.008,12A12,12,0,1,0,12,24.008q.373,0,.744-.023c.3-.019.605-.053.906-.095h0a11.956,11.956,0,0,0,4.038-1.316h0A11.99,11.99,0,0,0,24.008,12Zm-7.614,9.256-.045.023c-.281.132-.57.246-.863.35-.088.031-.176.063-.264.091-.269.088-.543.163-.819.228-.115.027-.23.05-.345.073-.265.053-.531.1-.8.13-.125.015-.25.023-.376.034q-.309.026-.619.033-.263.007-.525,0-.308-.007-.616-.033c-.127-.011-.253-.019-.379-.034-.267-.032-.531-.077-.794-.129-.118-.023-.236-.047-.352-.075-.274-.065-.545-.139-.812-.226-.091-.03-.182-.062-.272-.094-.29-.1-.578-.217-.857-.348l-.047-.023V17.272a1.466,1.466,0,0,1,1.464-1.464H14.93a1.466,1.466,0,0,1,1.464,1.464Zm1.757-1.062V17.272a3.225,3.225,0,0,0-3.221-3.221H9.073a3.225,3.225,0,0,0-3.221,3.221V20.2h0a10.25,10.25,0,1,1,12.3,0Z"
          fill="#02af56"
        />
      </g>
    </svg>
  );
};

export default UserSvgIcon;
