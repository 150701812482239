import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import SocialMediaLogins from "../../components/SocialMediaLogins/SocialMediaLogins";
import "./RegisterPage.css";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { signupUser } from "../../redux/user/userSlice";
import Loader from "../../components/Loader/Loader";
import Email from "../../assests/JsxSvgs/Email";
import Lock from "../../assests/JsxSvgs/Lock";
import Phone from "../../assests/JsxSvgs/Phone";

const RegisterPage = () => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    phone: "",
  });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const inputChangeHandler = (e) => {
    let name = e.target.name;
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const cb = (success, res) => {
    console.log(success, "success");
    console.log(res, "response");
    if (success) {
      toast.success(res?.data?.message || "User Created Successfully");
      navigate("/");
    } else {
      if (res?.errors) {
        setErrors(res?.errors);
        return;
      }
      toast.error(res?.data?.message || "Something went wrong, Try again");
    }
  };

  console.log(errors, "some errors");
  const formSubmitHandler = (e) => {
    e.preventDefault();
    console.log(values);
    dispatch(signupUser({ payload: values, cb: cb }));
  };
  const { isLoading } = useSelector((store) => store.user);

  return (
    <>
      <Loader loading={isLoading} />

      <div className="login-page">
        <div className="center-in-page">
          <h2 className="heading">Register</h2>
          <form onSubmit={formSubmitHandler}>
            <div className="flex flex-column">
              <div
                className={`input relative ${errors?.phone ? " error" : ""}`}
              >
                <div className="icon_cont">
                  <Phone />
                </div>
                <input
                  type="text"
                  placeholder="Phone Number"
                  name="phone"
                  className="basic-input"
                  value={values.phone}
                  required
                  onChange={inputChangeHandler}
                />
              </div>
              {errors?.phone?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>
            <div className="flex flex-column">
              <div
                className={`input relative ${errors?.email ? " error" : ""}`}
              >
                <div className="icon_cont">
                  <Email />
                </div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="basic-input"
                  value={values.email}
                  onChange={inputChangeHandler}
                  required
                />
              </div>
              {errors?.email?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <dvi className="flex flex-column">
              <div
                className={`input relative ${errors?.password ? " error" : ""}`}
              >
                <div className="icon_cont">
                  <Lock />
                </div>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  className="basic-input"
                  value={values.password}
                  onChange={inputChangeHandler}
                />
              </div>
              {errors?.password?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </dvi>

            <div className="flex justify-between">
              <div></div>
              <Link to="/forgot" className="forgot_password">
                Forgot Password ?
              </Link>
            </div>
            <button disabled={isLoading} className="login-btn">
              Register
            </button>
          </form>
          <div>{/* <SocialMediaLogins /> */}</div>
        </div>

        <div className="flex login_bottom align-center justify-center">
          <p className="mr-5">Already Have Account?</p>
          <Link to="/login">Login</Link>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
