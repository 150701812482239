import React from "react";

const PercentageIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="42.618"
        height="42.6"
        viewBox="0 0 42.618 42.6"
      >
        <g id="Group_6" data-name="Group 6" transform="translate(-0.691 -0.7)">
          <path
            id="Path_14"
            data-name="Path 14"
            d="M22,43a3.1,3.1,0,0,1-2.249-.955l-2.316-2.4a3.309,3.309,0,0,0-2.567-.8l-3.348.639a3.142,3.142,0,0,1-2.406-.493A2.884,2.884,0,0,1,7.88,36.95l-.4-3.248A3.088,3.088,0,0,0,5.894,31.61l-3.1-1.367A2.957,2.957,0,0,1,1.149,28.49,2.8,2.8,0,0,1,1.4,26.146l1.669-2.852a2.96,2.96,0,0,0,0-2.586L1.4,17.855a2.8,2.8,0,0,1-.254-2.345,2.955,2.955,0,0,1,1.644-1.753l3.1-1.367A3.093,3.093,0,0,0,7.481,10.3l.4-3.248a2.886,2.886,0,0,1,1.233-2.04,3.144,3.144,0,0,1,2.406-.493l3.348.64a3.3,3.3,0,0,0,2.568-.8l2.316-2.4A3.1,3.1,0,0,1,22,1h0a3.1,3.1,0,0,1,2.249.955l2.316,2.4a3.3,3.3,0,0,0,2.568.8l3.347-.64a3.149,3.149,0,0,1,2.406.493A2.889,2.889,0,0,1,36.12,7.05l.4,3.247a3.091,3.091,0,0,0,1.587,2.092l3.1,1.367a2.96,2.96,0,0,1,1.644,1.754,2.8,2.8,0,0,1-.254,2.345l-1.67,2.851a2.968,2.968,0,0,0,0,2.587L42.6,26.144a2.8,2.8,0,0,1,.254,2.345,2.952,2.952,0,0,1-1.644,1.753L38.1,31.61A3.094,3.094,0,0,0,36.518,33.7l-.4,3.248a2.887,2.887,0,0,1-1.234,2.04,3.14,3.14,0,0,1-2.406.493l-3.347-.639a3.3,3.3,0,0,0-2.568.8l-2.316,2.4A3.091,3.091,0,0,1,22,43Zm-6.742-6.251a5.475,5.475,0,0,1,3.758,1.5l2.316,2.4a.911.911,0,0,0,1.338,0l2.315-2.4a5.442,5.442,0,0,1,4.569-1.422l3.347.639a.94.94,0,0,0,.721-.14.861.861,0,0,0,.362-.613l.4-3.247a5.1,5.1,0,0,1,2.823-3.723l3.1-1.367a.887.887,0,0,0,.5-.519.836.836,0,0,0-.084-.7L39.052,24.3a4.886,4.886,0,0,1,0-4.6l1.67-2.852a.837.837,0,0,0,.084-.7.884.884,0,0,0-.5-.519l-3.1-1.367a5.1,5.1,0,0,1-2.824-3.723l-.4-3.248a.865.865,0,0,0-.362-.614.942.942,0,0,0-.721-.14l-3.348.64a5.449,5.449,0,0,1-4.569-1.422l-2.316-2.4a.931.931,0,0,0-1.338,0l-2.316,2.4a5.447,5.447,0,0,1-4.568,1.422L11.1,6.537a.941.941,0,0,0-.721.14.862.862,0,0,0-.361.614l-.4,3.248a5.094,5.094,0,0,1-2.823,3.723l-3.1,1.367a.884.884,0,0,0-.5.519.838.838,0,0,0,.084.7L4.948,19.7a4.885,4.885,0,0,1,0,4.6L3.279,27.153a.838.838,0,0,0-.084.7.886.886,0,0,0,.5.519l3.1,1.367a5.092,5.092,0,0,1,2.823,3.722l.4,3.248a.862.862,0,0,0,.361.613.945.945,0,0,0,.721.14l3.348-.639a4.346,4.346,0,0,1,.811-.074Zm5.284-34.1h0Z"
            fill="#00a8df"
          />
          <path
            id="Path_15"
            data-name="Path 15"
            d="M22,43a3.1,3.1,0,0,1-2.249-.955l-2.316-2.4a3.309,3.309,0,0,0-2.567-.8l-3.348.639a3.142,3.142,0,0,1-2.406-.493A2.884,2.884,0,0,1,7.88,36.95l-.4-3.248A3.088,3.088,0,0,0,5.894,31.61l-3.1-1.367A2.957,2.957,0,0,1,1.149,28.49,2.8,2.8,0,0,1,1.4,26.146l1.669-2.852a2.96,2.96,0,0,0,0-2.586L1.4,17.855a2.8,2.8,0,0,1-.254-2.345,2.955,2.955,0,0,1,1.644-1.753l3.1-1.367A3.093,3.093,0,0,0,7.481,10.3l.4-3.248a2.886,2.886,0,0,1,1.233-2.04,3.144,3.144,0,0,1,2.406-.493l3.348.64a3.3,3.3,0,0,0,2.568-.8l2.316-2.4A3.1,3.1,0,0,1,22,1h0a3.1,3.1,0,0,1,2.249.955l2.316,2.4a3.3,3.3,0,0,0,2.568.8l3.347-.64a3.149,3.149,0,0,1,2.406.493A2.889,2.889,0,0,1,36.12,7.05l.4,3.247a3.091,3.091,0,0,0,1.587,2.092l3.1,1.367a2.96,2.96,0,0,1,1.644,1.754,2.8,2.8,0,0,1-.254,2.345l-1.67,2.851a2.968,2.968,0,0,0,0,2.587L42.6,26.144a2.8,2.8,0,0,1,.254,2.345,2.952,2.952,0,0,1-1.644,1.753L38.1,31.61A3.094,3.094,0,0,0,36.518,33.7l-.4,3.248a2.887,2.887,0,0,1-1.234,2.04,3.14,3.14,0,0,1-2.406.493l-3.347-.639a3.3,3.3,0,0,0-2.568.8l-2.316,2.4A3.091,3.091,0,0,1,22,43Zm-6.742-6.251a5.475,5.475,0,0,1,3.758,1.5l2.316,2.4a.911.911,0,0,0,1.338,0l2.315-2.4a5.442,5.442,0,0,1,4.569-1.422l3.347.639a.94.94,0,0,0,.721-.14.861.861,0,0,0,.362-.613l.4-3.247a5.1,5.1,0,0,1,2.823-3.723l3.1-1.367a.887.887,0,0,0,.5-.519.836.836,0,0,0-.084-.7L39.052,24.3a4.886,4.886,0,0,1,0-4.6l1.67-2.852a.837.837,0,0,0,.084-.7.884.884,0,0,0-.5-.519l-3.1-1.367a5.1,5.1,0,0,1-2.824-3.723l-.4-3.248a.865.865,0,0,0-.362-.614.942.942,0,0,0-.721-.14l-3.348.64a5.449,5.449,0,0,1-4.569-1.422l-2.316-2.4a.931.931,0,0,0-1.338,0l-2.316,2.4a5.447,5.447,0,0,1-4.568,1.422L11.1,6.537a.941.941,0,0,0-.721.14.862.862,0,0,0-.361.614l-.4,3.248a5.094,5.094,0,0,1-2.823,3.723l-3.1,1.367a.884.884,0,0,0-.5.519.838.838,0,0,0,.084.7L4.948,19.7a4.885,4.885,0,0,1,0,4.6L3.279,27.153a.838.838,0,0,0-.084.7.886.886,0,0,0,.5.519l3.1,1.367a5.092,5.092,0,0,1,2.823,3.722l.4,3.248a.862.862,0,0,0,.361.613.945.945,0,0,0,.721.14l3.348-.639a4.346,4.346,0,0,1,.811-.074Zm5.284-34.1h0Z"
            fill="none"
            stroke="#00a8df"
            strokeWidth="0.6"
          />
          <path
            id="Path_16"
            data-name="Path 16"
            d="M12.533,31.077a1.149,1.149,0,0,1,0-1.673L29.739,12.922a1.258,1.258,0,0,1,1.728,0,1.149,1.149,0,0,1,0,1.673L14.261,31.077Zm0,0a1.245,1.245,0,0,0,.864.344m-.864-.344.864.344m0,0a1.245,1.245,0,0,0,.864-.344Z"
            fill="#00a8df"
            stroke="#00a8df"
            strokeWidth="0.3"
          />
          <path
            id="Path_17"
            data-name="Path 17"
            d="M12.172,16.849a4.369,4.369,0,0,0,4.452,4.27,4.369,4.369,0,0,0,4.451-4.27,4.369,4.369,0,0,0-4.451-4.271A4.369,4.369,0,0,0,12.172,16.849Zm2.451,0a2,2,0,1,1,2,1.91A1.959,1.959,0,0,1,14.623,16.849Z"
            fill="#00a8df"
            stroke="#00a8df"
            strokeWidth="0.3"
          />
          <path
            id="Path_18"
            data-name="Path 18"
            d="M22.925,27.15a4.455,4.455,0,1,0,4.451-4.271A4.369,4.369,0,0,0,22.925,27.15Zm2.451,0a2,2,0,1,1,2,1.91A1.959,1.959,0,0,1,25.376,27.15Z"
            fill="#00a8df"
            stroke="#00a8df"
            strokeWidth="0.3"
          />
        </g>
      </svg>
    </div>
  );
};

export default PercentageIcon;
