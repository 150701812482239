
import shop1 from "../../../assests/Images/shops_square/shop_1.png";
import shop2 from "../../../assests/Images/shops_square/shop_2.png";
import shop3 from "../../../assests/Images/shops_square/shop_3.png";
import shop4 from "../../../assests/Images/shops_square/shop_4.png";
import shop5 from "../../../assests/Images/shops_square/shop_5.png";
import shop6 from "../../../assests/Images/shops_square/shop_6.png";
export const shopsData = [
    {
        id: "01",
        image: shop1,
        name: 'KFC',
        tag_name: 'Restaurant',
        color: 'red',
        description: 'New offer by KFC is here! Description of new offer write here, Description of merchant write here. can insert take like this. 10%'
    },
    {
        id: "02",
        image: shop2,
        name: 'LAZADA',
        tag_name: 'Online Purchase',
        color: 'purple',
        description: " Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolor, vitae. Lorem, ipsum dolor."
    },
    {
        id: "03",
        image: shop3,
        name: 'Giant',
        tag_name: 'Grocery',
        color: 'green',
        description: " Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolor, vitae. Lorem, ipsum dolor."

    },
    {
        id: "04",
        image: shop4,
        name: 'Air Malasia',
        tag_name: 'Travel',
        color: 'blue',
        description: " Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolor, vitae. Lorem, ipsum dolor."

    },
    {
        id: "05",
        image: shop5,
        name: "McDonald's",
        tag_name: 'Restaurant',
        color: 'red',
        description: " Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolor, vitae. Lorem, ipsum dolor."

    },
    {
        id: "06",
        image: shop6,
        name: 'Oil India',
        tag_name: 'Petrol',
        color: 'green',
        description: " Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolor, vitae. Lorem, ipsum dolor."

    },
]