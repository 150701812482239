import React, { useEffect } from "react";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import cardImage from "../../assests/Images/card.jpg";
import "./CardList.css";
import RectEdit from "../../assests/JsxSvgs/RectEdit";
import CardListItem from "./CardListItem";
import { cardData } from "./data";
import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchCreditCards } from "../../redux/creditCardsSlice";
import SearchBar from "../../components/SearchBar/SearchBar";
import CreateCard from "./CreateCard/CreateCard";
import Plus from "../../assests/JsxSvgs/Plus";
import Loader from "../../components/Loader/Loader";

const CardList = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCreditCards());
  }, []);
  const { isLoading, cards } = useSelector((store) => store?.cards);
  const [showCreateCard, setShowCreateCard] = useState(false);
  const [editData, setEditData] = useState(null);
  const closeCreateCardModal = () => {
    setShowCreateCard(false);
    if (editData) setEditData(null);
  };
  const OpenCreateCardModal = (editData) => {
    setShowCreateCard(true);
    setEditData(editData);
  };
  return (
    <div className="page">
      <Loader loading={isLoading} />
      <CreateCard
        show={showCreateCard}
        closer={closeCreateCardModal}
        data={editData}
        isEdit={editData}
      />
      <div className="bg_primary">
        <GoBackHeader title="All Cards" />
        <div className="flex align-center">
          <div className="flex-1">
            <SearchBar />
          </div>
          <button
            onClick={OpenCreateCardModal}
            className="btn-header flex align-center"
          >
            Add New
            <Plus />
          </button>
        </div>
      </div>

      <div className="card-list-page p-20">
        <div className="card-list-items">
          {cards?.map((card, i) => (
            <div onClick={() => OpenCreateCardModal(card)}>
              <CardListItem s_no={i + 1} {...card} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardList;
