import { configureStore } from '@reduxjs/toolkit'
import userReducer from './user/userSlice'
import pagesReducer from './pagesSlice'
import cardsReducer from './creditCardsSlice'
import shopsReducer from './shopsSlice'
import newsReducer from './newsSlice'
import notificationReducer from './notificationSlice'
import categoryReducer from './categorySlice'

export const store = configureStore({
    reducer: {
        user: userReducer,
        pages: pagesReducer,
        cards: cardsReducer,
        shops: shopsReducer,
        news: newsReducer,
        notifications: notificationReducer,
        categories: categoryReducer
    },
})