import React from "react";

const HomeIcons = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clipPath">
          <rect
            id="Rectangle_1"
            data-name="Rectangle 1"
            width="24"
            height="24"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Group_2" data-name="Group 2" clipPath="url(#clipPath)">
        <path
          id="Path_5"
          data-name="Path 5"
          d="M21.448,13.243l0,8.693H11.209V14.782a.579.579,0,0,0-.2-.436h0a.715.715,0,0,0-.469-.171H4.206a.643.643,0,0,0-.673.606v7.154H2.785V13.243a4.067,4.067,0,0,0,1.981-.178,3.568,3.568,0,0,0,1.557-1.06,3.773,3.773,0,0,0,2.9,1.289,3.773,3.773,0,0,0,2.9-1.288,3.9,3.9,0,0,0,5.793,0,3.568,3.568,0,0,0,1.557,1.06Zm0,0a4.066,4.066,0,0,1-1.981-.178Zm2.537-1.653a2.3,2.3,0,0,0,.166-1.967h.01l-.087-.181-.013-.028,0-.006L20.618,2.219h0a.678.678,0,0,0-.61-.368H4.2a.678.678,0,0,0-.61.369h0L.155,9.408a2.306,2.306,0,0,0,.07,2.183h0a3.267,3.267,0,0,0,1.2,1.176v9.776a.579.579,0,0,0,.2.436h0a.715.715,0,0,0,.469.17H22.114a.715.715,0,0,0,.469-.17h0a.579.579,0,0,0,.2-.436V12.768a3.267,3.267,0,0,0,1.2-1.176Zm-1.2-.566,0,0a2.206,2.206,0,0,1-1.51,1.011,2.494,2.494,0,0,1-1.868-.379,1.839,1.839,0,0,1-.831-1.471.677.677,0,0,0-1.347,0,1.9,1.9,0,0,1-1.123,1.6l.066.135-.066-.135a2.528,2.528,0,0,1-2.194,0h0a1.9,1.9,0,0,1-1.123-1.6.678.678,0,0,0-1.347,0,1.9,1.9,0,0,1-1.123,1.6,2.528,2.528,0,0,1-2.194,0A1.9,1.9,0,0,1,7,10.187a.678.678,0,0,0-1.347,0,1.838,1.838,0,0,1-.831,1.471,2.494,2.494,0,0,1-1.868.379,2.206,2.206,0,0,1-1.51-1.01,1.2,1.2,0,0,1-.027-1.139v0L4.652,3.063h14.91l3.252,6.824h0v0A1.2,1.2,0,0,1,22.789,11.025ZM9.862,21.937H4.882V15.389h4.98Z"
          fill="#fc8415"
          stroke="#fc8415"
          strokeWidth="0.3"
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M20.475,14.347h0a.715.715,0,0,0-.469-.17H12.63a.643.643,0,0,0-.674.606v4.565a.579.579,0,0,0,.2.436.715.715,0,0,0,.469.171h7.377a.715.715,0,0,0,.469-.171.579.579,0,0,0,.2-.436V14.783A.579.579,0,0,0,20.475,14.347ZM19.33,15.389l0,3.352H13.3V15.389Z"
          fill="#fc8415"
          stroke="#fc8415"
          strokeWidth="0.3"
        />
      </g>
    </svg>
  );
};

export default HomeIcons;
