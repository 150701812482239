import React from "react";

const Email = (props) => {
  return (
    <svg
      height="20"
      width="20"
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.003 5.884 10 9.882l7.997-3.998A2 2 0 0 0 16 4H4a2 2 0 0 0-1.997 1.884Z"
        fill="#ADB0BA"
      />
      <path
        d="m18 8.118-8 4-8-4V14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8.118Z"
        fill="#ADB0BA"
      />
    </svg>
  );
};

export default Email;
