import React from "react";

const MessageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clipPath">
          <rect
            id="Rectangle_2"
            data-name="Rectangle 2"
            width="24"
            height="24"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Group_3" data-name="Group 3" clipPath="url(#clipPath)">
        <path
          id="Path_7"
          data-name="Path 7"
          d="M21.449,1H2.551a2.342,2.342,0,0,0-1.8.922A3.553,3.553,0,0,0,0,4.143v20a.873.873,0,0,0,.464.812A.553.553,0,0,0,.691,25a.653.653,0,0,0,.557-.343l3.247-5.1H21.449a2.341,2.341,0,0,0,1.8-.922A3.552,3.552,0,0,0,24,16.417V4.143a3.552,3.552,0,0,0-.749-2.221A2.341,2.341,0,0,0,21.449,1Zm1.16,15.428a1.618,1.618,0,0,1-.341,1.009,1.067,1.067,0,0,1-.819.42H4.156a.657.657,0,0,0-.547.326L1.392,21.651V4.143a1.617,1.617,0,0,1,.341-1.008,1.067,1.067,0,0,1,.819-.42h18.9a1.067,1.067,0,0,1,.819.42,1.618,1.618,0,0,1,.341,1.008ZM19.826,6.514a.789.789,0,0,1-.7.857H10.419a.789.789,0,0,1-.7-.857.789.789,0,0,1,.7-.857h8.73a.787.787,0,0,1,.7.846Zm0,3.777h0a.789.789,0,0,1-.7.857H4.852a.789.789,0,0,1-.7-.857.789.789,0,0,1,.7-.857h14.3a.787.787,0,0,1,.7.846Zm0,3.777h0a.789.789,0,0,1-.7.857H4.852a.789.789,0,0,1-.7-.857.789.789,0,0,1,.7-.857h14.3a.787.787,0,0,1,.7.846Z"
          fill="#0059df"
        />
      </g>
    </svg>
  );
};

export default MessageIcon;
