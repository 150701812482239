import React from "react";
import { useNavigate } from "react-router-dom";
import AtmCardIcon from "../../assests/JsxSvgs/AtmCardIcon";
import BankDetailsIcon from "../../assests/JsxSvgs/BankDetailsIcon";
import BellIcon from "../../assests/JsxSvgs/BellIcon";
import NewsListNewIcon from "../../assests/JsxSvgs/NewsListNewIcon";
import PlainHomeIcon from "../../assests/JsxSvgs/PlainHomeIcon";
import ShopsBankIcon from "../../assests/JsxSvgs/ShopsBankIcon";
import UserImage from "../../assests/Images/man.png";
import ReactDOM from "react-dom";
import "./SideNav.css";
import { GrClose } from "react-icons/gr";
import LogoutIcon from "../../assests/JsxSvgs/LogoutIcon";
import { useDispatch, useSelector } from "react-redux";
import { clearUserInfo, signOutUser } from "../../redux/user/userSlice";

const SideNav = ({ closer }) => {
  const navigate = useNavigate();
  const navitemClickHandler = (to) => {
    closer();
    navigate(to);
  };
  const { user, isLoading } = useSelector((data) => data.user || {});
  const dispatch = useDispatch();

  const signInHandler = () => {
    closer();
    if (user) {
      dispatch(signOutUser({ cb: closer }));
      dispatch(clearUserInfo());
    } else {
      navigate("/login");
    }
  };
  console.log(user, "side nav");
  return ReactDOM.createPortal(
    <>
      <div className="back_drop" onClick={closer}></div>
      <div className="side_nav_root">
        <div className="flex flex-column h-100 justify-between">
          <div className="content">
            <div className=" close_cont flex justify-between">
              <div></div>
              <GrClose size={20} onClick={closer} />
            </div>

            <div className="side_nav_padding">
              <div className="user_info flex align-center">
                <div className="user_image">
                  <img src={user?.user?.image} alt="" />
                </div>
                <div className="flex flex-column">
                  <h3 className="user_name">{user?.user?.name || ""}</h3>
                  <p className="description_text">Customer</p>
                </div>
              </div>
              <div className="menu_items">
                <div
                  onClick={() => {
                    navitemClickHandler("/");
                  }}
                  className="menu_item flex align-center"
                >
                  <div className="icon_cont">
                    <PlainHomeIcon />
                  </div>
                  <p className="menu_text">Home</p>
                </div>
                <div
                  onClick={() => {
                    navitemClickHandler("/notifications");
                  }}
                  className="menu_item flex align-center"
                >
                  <div className="icon_cont">
                    <BellIcon />
                  </div>
                  <p className="menu_text">Notifications</p>
                </div>
                <div
                  onClick={() => {
                    navitemClickHandler("/");
                  }}
                  className="menu_item flex align-center"
                >
                  <div className="icon_cont">
                    <BankDetailsIcon />
                  </div>
                  <p className="menu_text">Update bank details</p>
                </div>
                <div
                  onClick={() => {
                    navitemClickHandler("/mycards");
                  }}
                  className="menu_item flex align-center"
                >
                  <div className="icon_cont">
                    <AtmCardIcon />
                  </div>
                  <p className="menu_text">My Cards</p>
                </div>
                <div
                  onClick={() => {
                    navitemClickHandler("/");
                  }}
                  className="menu_item flex align-center"
                >
                  <div className="icon_cont">
                    <ShopsBankIcon />
                  </div>
                  <p className="menu_text">Shops (admin & bank )</p>
                </div>
                <div
                  onClick={() => {
                    navitemClickHandler("/");
                  }}
                  className="menu_item flex align-center"
                >
                  <div className="icon_cont">
                    <NewsListNewIcon />
                  </div>
                  <p className="menu_text">News List (admin & bank )</p>
                </div>
                <div
                  onClick={() => {
                    navitemClickHandler("/cardlist");
                  }}
                  className="menu_item flex align-center"
                >
                  <div className="icon_cont">
                    <AtmCardIcon />
                  </div>
                  <p className="menu_text">All Cards (admin & bank )</p>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={signInHandler}
            className="btn logout flex align-center "
          >
            <div className="logout_icon">
              <LogoutIcon />
            </div>
            {user ? "Log Out" : "Sign In"}
          </button>
        </div>
      </div>
    </>,
    document.getElementById("side_nav")
  );
};

export default SideNav;
