import React from "react";

const AttachmentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.919"
      height="17.932"
      viewBox="0 0 17.919 17.932"
    >
      <g id="Group_9" data-name="Group 9" transform="translate(-3.081 -3.08)">
        <path
          id="Path_27"
          data-name="Path 27"
          d="M12.247,4.769l-2.2,2.2L11.084,8l2.2-2.2a3.779,3.779,0,0,1,5.309-.318,3.788,3.788,0,0,1-.318,5.309l-2.2,2.2,1.036,1.036,2.2-2.2a5.239,5.239,0,0,0,.318-7.381,5.241,5.241,0,0,0-7.381.318Z"
          fill="#2565ff"
        />
        <path
          id="Path_28"
          data-name="Path 28"
          d="M10.788,18.28a4.208,4.208,0,0,1-2.756,1.253,3.232,3.232,0,0,1-3.486-3.486A4.208,4.208,0,0,1,5.8,13.291l2.2-2.2L6.962,10.057l-2.2,2.2h0a5.67,5.67,0,0,0-1.681,3.719v.271A4.688,4.688,0,0,0,8.105,21a5.671,5.671,0,0,0,3.719-1.681l2.2-2.2-1.036-1.036Z"
          fill="#2565ff"
        />
        <path
          id="Path_29"
          data-name="Path 29"
          d="M7.847,15.2l7.339-7.339,1.037,1.037L8.884,16.232Z"
          fill="#2565ff"
        />
      </g>
    </svg>
  );
};

export default AttachmentIcon;
