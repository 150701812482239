import React from "react";

const FileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="22"
      viewBox="0 0 24 22"
    >
      <g id="Group_4" data-name="Group 4" transform="translate(0 -1)">
        <path
          id="Path_8"
          data-name="Path 8"
          d="M4.167,1A4.167,4.167,0,0,0,0,5.167V18.833A4.167,4.167,0,0,0,4.167,23h9.119a4.167,4.167,0,0,0,2.947-1.22l6.547-6.548A4.166,4.166,0,0,0,24,12.285V5.167A4.167,4.167,0,0,0,19.833,1Zm-2.5,4.167a2.5,2.5,0,0,1,2.5-2.5H19.833a2.5,2.5,0,0,1,2.5,2.5v7.167H17.5A4.167,4.167,0,0,0,13.333,16.5v4.833H4.167a2.5,2.5,0,0,1-2.5-2.5ZM15,20.653V16.5A2.5,2.5,0,0,1,17.5,14h4.153l-.052.053L15.054,20.6Z"
          fill="#995dff"
        />
        <path
          id="Path_9"
          data-name="Path 9"
          d="M18.316,6.561a.73.73,0,0,0,.665-.781L19,5.77A.728.728,0,0,0,18.335,5H4.665A.73.73,0,0,0,4,5.78a.73.73,0,0,0,.665.78Z"
          fill="#995dff"
        />
        <path
          id="Path_10"
          data-name="Path 10"
          d="M18.316,10a.73.73,0,0,0,.665-.781L19,9.209a.728.728,0,0,0-.665-.77H4.665A.73.73,0,0,0,4,9.22a.73.73,0,0,0,.665.78Z"
          fill="#995dff"
        />
      </g>
    </svg>
  );
};

export default FileIcon;
