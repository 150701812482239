import React from "react";

import CategoryCard from "../../../components/CategoryCard/CategoryCard";
import { useSelector } from "react-redux";

let cardTypes = {
  0: "red",
  1: "purple",
  2: "yellow",
  3: "green",
  4: "pink",
  5: "blue",
};

const CategoryItems = () => {
  const { data } = useSelector((store) => store?.categories);
  return (
    <div className=" cards_scroll ">
      {data?.map((each, i) => (
        <CategoryCard {...each} color={cardTypes[i % 5]} />
      ))}
    </div>
  );
};

export default CategoryItems;
