import React from "react";

const DeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18.75"
      viewBox="0 0 18 18.75"
    >
      <path
        id="Path_32"
        data-name="Path 32"
        d="M5.438,2.313H5.25a.188.188,0,0,0,.188-.187v.188h7.125V2.125a.188.188,0,0,0,.188.188h-.187V4H14.25V2.125a1.5,1.5,0,0,0-1.5-1.5H5.25a1.5,1.5,0,0,0-1.5,1.5V4H5.438ZM17.25,4H.75A.749.749,0,0,0,0,4.75V5.5a.188.188,0,0,0,.188.188H1.6l.579,12.258a1.5,1.5,0,0,0,1.5,1.43H14.32a1.5,1.5,0,0,0,1.5-1.43L16.4,5.688h1.416A.188.188,0,0,0,18,5.5V4.75A.749.749,0,0,0,17.25,4ZM14.14,17.688H3.86l-.567-12H14.707Z"
        transform="translate(0 -0.625)"
        fill="#ff0101"
      />
    </svg>
  );
};

export default DeleteIcon;
