import React from "react";

const Lock = (props) => {
  return (
    <svg
      height="20"
      width="20"
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 9V7a5 5 0 1 1 10 0v2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2Zm8-2v2H7V7a3 3 0 1 1 6 0Z"
        fill="#ADB0BA"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Lock;
