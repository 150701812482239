export const CATEGORIES = [
  {
    label: "All",
    key: 1,
  },
  {
    label: "Restaurant",
    key: 2,
  },
  {
    label: "Petrol",
    key: 3,
  },
  {
    label: "Fashion",
    key: 4,
  },
  {
    label: "Grocery",
    key: 5,
  },
  {
    label: "E-Wallet",
    key: 6,
  },
];
