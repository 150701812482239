import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import Loader from "../../components/Loader/Loader";
import "./Restaurant.css";
let cardTypes = {
  0: "red",
  1: "purple",
  2: "yellow",
  3: "green",
  4: "pink",
  5: "blue",
};

export const CATEGORIES = [
  {
    label: "Merchants",
    key: 1,
  },
  {
    label: "Cards",
    key: 2,
  },
];
export const SUB_CATEGORIES = [
  {
    label: "Today's Suggested",
    key: 1,
  },
  {
    label: "All Time",
    key: 2,
  },
];
const Restaurant = () => {
  const { id } = useParams();
  const [activeCategory, setActiveCategory] = useState(CATEGORIES[0].key);
  const [activeSub, setActiveSub] = useState(SUB_CATEGORIES[0].key);
  const [shops, setShops] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    axios
      .get("/merchant", { params: { category_id: id } })
      .then((res) => {
        setLoading(false);
        if (res?.data?.success) {
          setShops(res?.data?.data);
        } else {
          toast.error(
            res?.data?.message || "Something went wrong ,Try again.."
          );
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(
          e?.response?.data?.message || "Something went wrong ,Try again.."
        );
      });
  }, []);
  return (
    <div className="page restaurant_page_root">
      <Loader loading={loading} />
      <div className="sticky_top_nav">
        <GoBackHeader title="Restaurant" />
        <div className="card overflow-x category_card">
          <ul className="my_cards_categories">
            {CATEGORIES.map((each) => (
              <li
                onClick={() => {
                  setActiveCategory(each.key);
                }}
                key={each.key}
                className={`${each.key == activeCategory ? "pill" : ""}`}
              >
                {each.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="restaurant_page shop_page_cont p-20">
        <ul className="my_cards_categories mb-20">
          {SUB_CATEGORIES.map((each) => (
            <li
              onClick={() => {
                setActiveSub(each.key);
              }}
              key={each.key}
              className={`${each.key == activeSub ? "pill" : ""}`}
            >
              {each.label}
            </li>
          ))}
        </ul>
        <div className="card">
          {shops?.map((shop, i) => (
            <div
              onClick={console.log(shop)}
              key={shop.id}
              className="flex justify-between align-center info_page_item"
            >
              <div className="flex shop_item align-center">
                <p className="order">{i + 1}</p>
                <div className="icon_cont ">
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/${shop.image}`}
                    alt=""
                  />
                </div>
                <div className="flex flex-column align-start">
                  <p className="text">{shop.name}</p>
                  <p
                    className={`tag color_${
                      cardTypes[shop?.category_id % 5]
                    } bg_${cardTypes[shop?.category_id % 5]}_light`}
                  >
                    {shop.category_name || `category ${shop?.category_id}`}
                  </p>
                </div>
              </div>
            </div>
          ))}
          {shops && !loading && !shops?.length ? (
            <p className="no_data_msg">No data found..</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Restaurant;
