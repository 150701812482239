import React from "react";

const Plus = (props) => {
  return (
    <svg
      height="20"
      width="20"
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 2C9.42377 2 8.95647 2.46729 8.95647 3.04353V8.95647H3.04353C2.46731 8.95647 2 9.42351 2 10C2 10.5762 2.46729 11.0435 3.04353 11.0435H8.95647V16.9565C8.95647 17.5327 9.42376 18 10 18C10.5762 18 11.0435 17.5327 11.0435 16.9565V11.0435H16.9565C17.5327 11.0435 18 10.5762 18 10C18 9.42376 17.5327 8.95647 16.9565 8.95647H11.0435V3.04353C11.0435 2.46731 10.5765 2 10 2Z"
        fill="white"
      />
    </svg>
  );
};

export default Plus;
