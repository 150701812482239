
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
const initialState = {
    news: null,
    isLoading: false,
    currentNews: null
}

export const fetchNews = createAsyncThunk('fetchNews', () => {
    return axios.get('/merchant').then(res => res.data)
});

export const creditCardsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        setCurrentNews: (state, action) => {
            state.currentNews = action.payload;
        },
    },
    extraReducers: {
        [fetchNews.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchNews.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.news = action.payload.data;
        },
        [fetchNews.rejected]: (state) => {
            state.isLoading = false;
        },


    },
})

export const { setCurrentNews } = creditCardsSlice.actions
export default creditCardsSlice.reducer;
