
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
const initialState = {
    shops: null,
    isLoading: false,
    currentShop: null
}

export const fetchShops = createAsyncThunk('fetchShops', () => {
    return axios.get('/merchant').then(res => res.data)
});

export const creditCardsSlice = createSlice({
    name: 'shops',
    initialState,
    reducers: {
        setCurrentShop: (state, action) => {
            state.currentShop = action.payload;
        },
    },
    extraReducers: {
        [fetchShops.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchShops.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.shops = action.payload.data;
        },
        [fetchShops.rejected]: (state) => {
            state.isLoading = false;
        },


    },
})

export const { setCurrentShop } = creditCardsSlice.actions
export default creditCardsSlice.reducer;
