import React from "react";
import ManImage from "../../../assests/Images/man.png";
import { IoEllipsisVertical } from "react-icons/io5";
const CurrentUserMessages = () => {
  return (
    <div className="flex current_user">
      <div className="profile_img">
        <img src={ManImage} alt="" />
      </div>
      <div>
        <div className="messages">
          <p className="message">Where can I use touch n go feature ?</p>
        </div>
        <p className="message_info">
          <span>George</span> . <span>12:00 PM</span>
        </p>
      </div>

      <div className="menu_icon">
        <IoEllipsisVertical />
      </div>
    </div>
  );
};

export default CurrentUserMessages;
