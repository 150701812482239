import React, { useState } from "react";
import CalenderTimerIcon from "../../../assests/JsxSvgs/CalenderTimerIcon";
import CoinsIcons from "../../../assests/JsxSvgs/coinsIcons";
import NoteIcon from "../../../assests/JsxSvgs/NoteIcon";
import RectEdit from "../../../assests/JsxSvgs/RectEdit";
import "./UserPage.css";
import ViewAllCardsModal from "./ViewAllCardsModal";

const UserPage = () => {
  const [showAllTransaction, setShowAllTransaction] = useState(false);
  const viewAllClickHandler = () => {
    setShowAllTransaction(true);
  };
  const closeAllClickHandler = () => {
    setShowAllTransaction(false);
  };
  return (
    <>
      <ViewAllCardsModal
        show={showAllTransaction}
        closer={closeAllClickHandler}
      />
      <div className="user_page_cont nm-1rem">
        <div className="item flex justify-between">
          <div className="flex align-center">
            <div className="icon_cont main_icon bg_yellow_light">
              <NoteIcon />
            </div>
            <div className="text">
              <p className="title_text">Statement date</p>
              <p onClick={viewAllClickHandler} className="link_text">
                View all Cards
              </p>
            </div>
          </div>
          <div className="flex  align-center">
            <p className="created_at">02 Sep</p>
            <div className="icon_cont">
              <RectEdit />
            </div>
          </div>
        </div>
        <div className="item flex justify-between">
          <div className="flex align-center">
            <div className="icon_cont main_icon bg_purple_light">
              <CalenderTimerIcon />
            </div>
            <div className="text">
              <p className="title_text">Due Date</p>
            </div>
          </div>
          <div className="flex  align-center">
            <p className="created_at">02 Sep</p>
            <div className="icon_cont">
              <RectEdit />
            </div>
          </div>
        </div>
        <div className="item flex justify-between">
          <div className="flex align-center">
            <div className="icon_cont main_icon bg_green_light color_green">
              <CoinsIcons />
            </div>
            <div className="text">
              <p className="title_text">Annual Fee date</p>
            </div>
          </div>
          <div className="flex  align-center">
            <p className="created_at">02 Sep</p>
            <div className="icon_cont">
              <RectEdit />
            </div>
          </div>
        </div>
        <div className="item flex justify-between">
          <div className="flex align-center">
            <div className="icon_cont main_icon bg_purple_light">
              <CalenderTimerIcon />
            </div>
            <div className="text">
              <p className="title_text">Service Tax</p>
            </div>
          </div>
          <div className="flex  align-center">
            <p className="created_at">02 Sep</p>
            <div className="icon_cont">
              <RectEdit />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserPage;
