export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {

      let { user } = store.getState(user => user);
      console.log(user, 'user data')
      let token = user?.user?.token
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    err => Promise.reject(err)
  );
}
