
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
const initialState = {
    cards: null,
    isLoading: false,


}

export const fetchCreditCards = createAsyncThunk('fetchCards', () => {
    return axios.get('/card').then(res => res.data)
});
// export const fetchCurrentMatchDetails = createAsyncThunk('/matches/each', (id) => {
//     return axios.get(`/matches?id=${id}`).then(res => res.data)
// });
// export const fetchResultAndHistory = createAsyncThunk('/result-history', () => {
//     return axios.get('/result-history').then(res => res.data)
// });
// export const fetchNotification = createAsyncThunk('/user/notifications', () => {
//     return axios.get('/user/notifications').then(res => res.data)
// });
// export const betRequestApiAction = createAsyncThunk('/bet', (data) => {
//     return axios.post('/bet', data.payload).then(res => {
//         if (res.data.success)
//             data.cb(true, res.data)
//         else
//             data.cb(false, res?.data)

//         return res.data
//     }
//     ).catch(e => {
//         data.cb(false, e.response?.data)
//         return e.response
//     })
// });



export const creditCardsSlice = createSlice({
    name: 'creditCards',
    initialState,
    reducers: {
        // setCurrentMatch: (state, action) => {
        //     state.currentMatch = action.payload;
        // },
    },
    extraReducers: {
        [fetchCreditCards.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchCreditCards.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.cards = action.payload.data;
        },
        [fetchCreditCards.rejected]: (state) => {
            state.isLoading = false;
        },


    },
})

// export const { setCurrentMatch } = matchesSlice.actions

export default creditCardsSlice.reducer;
