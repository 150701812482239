import React from "react";
import "./BankCards.css";
//color = purple || black
const BankCard = ({ color = "blue" }) => {
  return (
    <div className={`bankcard_root ${color}`}>
      <div className="flex justify-between align-center">
        <p className="card_type">PLATINUM</p>
        <div className="card_logo"></div>
      </div>
      <div>
        <div className="card_number">
          <p>
            <span>1234</span>
            <span>1234</span>
            <span>1234</span>
            <span>1234</span>
          </p>
        </div>
        <div className="flex gap-2 mt-15">
          <div className="flex flex-column card_holder">
            <p className="card_label">Card Holder</p>
            <p className="card_label_value">JOHN DOE</p>
          </div>
          <div className="flex flex-column">
            <p className="card_label">Expires</p>
            <p className="card_label_value">10/24</p>
          </div>
        </div>
      </div>
      <div className="two_circles">
        <div className="circle_red"></div>
        <div className="circle_yellow"></div>
      </div>
      <div className="absolute bg_circle bg_circle_1"></div>
      <div className="absolute bg_circle bg_circle_2"></div>
      <div className="absolute bg_circle bg_circle_3"></div>
      <div className="absolute bg_circle bg_circle_4"></div>
      <div className="absolute bg_circle bg_circle_5"></div>
      <div className="absolute bg_circle bg_circle_6"></div>
    </div>
  );
};

export default BankCard;
