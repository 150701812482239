
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
const initialState = {
    // downlineList: null,
    isLoading: true,
    // wallet: null,
    // bankInfo: {}
}

export const fetchDownlineList = createAsyncThunk('/my-downline', () => {
    return axios.get('/my-downline').then(res => res.data)
});
export const fetchWalletInfo = createAsyncThunk('/my-wallet', (data) => {
    return axios.get('/my-wallet').then(res => res.data)
});
export const fetchAccountInfo = createAsyncThunk('/user-transfer-info', (data) => {
    return axios.get('/user-transfer-info').then(
        res => res.data).catch(
            err => {
                return new Error(err);
            })
});


export const pagesSlice = createSlice({
    name: 'pagesSlice',
    initialState,
    reducers: {
        setBankInfo: (state, action) => {
            state.bankInfo.bank_info = action.payload;
        },
        setPaypalInfo: (state, action) => {
            state.bankInfo.paypal_info = action.payload;
        },
    },
    extraReducers: {
        [fetchDownlineList.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchDownlineList.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.downlineList = action.payload.data;
        },
        [fetchDownlineList.rejected]: (state) => {
            state.isLoading = false;
        },
        //// wallet info
        [fetchWalletInfo.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchWalletInfo.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.wallet = action.payload.data;
        },
        [fetchWalletInfo.rejected]: (state, payload) => {
            state.isLoading = false;
        },
        //// account info
        [fetchAccountInfo.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchAccountInfo.fulfilled]: (state, action) => {

            state.isLoading = false;
            state.bankInfo = action.payload.data;
        },
        [fetchAccountInfo.rejected]: (state, payload) => {
            state.isLoading = false;
        },

    },
})

export const { setBankInfo, setPaypalInfo } = pagesSlice.actions

export default pagesSlice.reducer;
