import React from "react";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import "./GoBackHeader.css";

const GoBackHeader = ({ title, goBackHandler }) => {
  const navigate = useNavigate();
  const backClickHandler = () => {
    navigate(-1);
  };
  return (
    <div className="go_back_header">
      <div className="flex align-center ">
        <div
          className="back_icon icon_cont mr-20"
          onClick={goBackHandler || backClickHandler}
        >
          <BiArrowBack size="25" />
        </div>
        <p className="go_back_title">{title}</p>
      </div>
    </div>
  );
};

export default GoBackHeader;
