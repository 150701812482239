import React, { useEffect, useState } from "react";
import BankCard from "../../components/BankCards/BankCard";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import { Link } from "react-router-dom";
import "./MyCards.css";
import { CATEGORIES } from "./SampleData";
import MobileWidthLayout from "../../components/MobileWidthLoyout/MobileWidthLayout";
import { useDispatch, useSelector } from "react-redux";
import { fetchCreditCards } from "../../redux/creditCardsSlice";
import Loader from "../../components/Loader/Loader";

const MyCards = () => {
  const [activeCategory, setActiveCategory] = useState(CATEGORIES[0].key);
  const { isLoading, cards } = useSelector((store) => store?.cards);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!cards) dispatch(fetchCreditCards());
  }, []);
  return (
    <MobileWidthLayout>
      <div className="my_cards_page">
        <Loader loading={isLoading} />
        <div className="sticky_top_nav">
          <GoBackHeader title="My Cards" />
          <div className="card overflow-x category_card">
            <ul className="my_cards_categories">
              {CATEGORIES.map((each) => (
                <li
                  onClick={() => {
                    setActiveCategory(each.key);
                  }}
                  key={each.key}
                  className={`${each.key == activeCategory ? "pill" : ""}`}
                >
                  {each.label}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="cards_list p-20">
          {cards?.map((card, i) => {
            if (i > 2) return null;
            else
              return (
                <Link
                  to={`/card-details/${card.id}`}
                  className="bankcard_root img py-0 "
                >
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/${card?.image}`}
                    alt=""
                    className="credit_image"
                  />
                </Link>
              );
          })}
        </div>
      </div>
    </MobileWidthLayout>
  );
};

export default MyCards;
