
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
const initialState = {
    data: null,
    isLoading: false,
    currentCategory: null
}

export const fetchCategories = createAsyncThunk('fetchCategories', () => {
    return axios.get('/category').then(res => res.data)
});

export const creditCardsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        setCurrentCategory: (state, action) => {
            state.currentCategory = action.payload;
        },
    },
    extraReducers: {
        [fetchCategories.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchCategories.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.data = action.payload.data;
        },
        [fetchCategories.rejected]: (state) => {
            state.isLoading = false;
        },


    },
})

export const { setCurrentCategory } = creditCardsSlice.actions
export default creditCardsSlice.reducer;
