
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
const initialState = {
    notifications: null,
    isLoading: false,
}

export const fetchNotifications = createAsyncThunk('fetchNotifications', () => {
    return axios.get('/notifications').then(res => res.data)
});

export const NotificationSlice = createSlice({
    name: 'notificaions',
    initialState,
    reducers: {
        setCurrentNotification: (state, action) => {
            state.currentNews = action.payload;
        },
    },
    extraReducers: {
        [fetchNotifications.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchNotifications.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.notifications = action.payload.data;
        },
        [fetchNotifications.rejected]: (state) => {
            state.isLoading = false;
        },


    },
})

export const { setCurrentNotification } = NotificationSlice.actions
export default NotificationSlice.reducer;
