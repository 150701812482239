import React, { useEffect } from "react";
import BankCard from "../../components/BankCards/BankCard";
import CategoryCard from "../../components/CategoryCard/CategoryCard";
import NavBar from "../../components/NavBar/NavBar";
import CategoryItems from "./CategoryItems/CategoryItems";
import "./Dashboard.css";

import Shops from "./Shops/Shops";
import NewsLetters from "./NewsLetters/NewsLetters";
import Discover from "./Discover/Discover";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import MobileWidthLayout from "../../components/MobileWidthLoyout/MobileWidthLayout";
import { useDispatch, useSelector } from "react-redux";
import { fetchCreditCards } from "../../redux/creditCardsSlice";
import Loader from "../../components/Loader/Loader";
import { fetchShops } from "../../redux/shopsSlice";
import { fetchNews } from "../../redux/newsSlice";
import { fetchCategories } from "../../redux/categorySlice";

const Dashboard = () => {
  const navigate = useNavigate();

  const viewAllMyCardsHandler = () => {
    navigate("/mycards");
  };

  const { isLoading, cards } = useSelector((store) => store?.cards);
  const { isLoading: shopsLoading } = useSelector((store) => store?.shops);
  const { isLoading: newsLoading } = useSelector((store) => store?.news);
  const { isLoading: categoryLoading } = useSelector(
    (store) => store?.categories
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCreditCards());
    dispatch(fetchShops());
    dispatch(fetchNews());
    dispatch(fetchCategories());
  }, []);

  console.log(cards, "some cards");
  return (
    <MobileWidthLayout>
      <Loader
        loading={isLoading || shopsLoading || newsLoading || categoryLoading}
      />
      <div>
        <NavBar />
        <div className="my_cards_cont">
          <div className="flex justify-between p-20">
            <p className="section_heading ">My Cards</p>
            <p
              onClick={viewAllMyCardsHandler}
              className="text-light view_all_btn"
            >
              View All
            </p>
          </div>
          <div className="pl-20 my_card_cont">
            <div className="overflow-x">
              <div className=" cards_scroll gap-10">
                {cards?.map((card, i) => {
                  if (i > 2) return null;
                  else
                    return (
                      <Link
                        to={`/card-details/${card.id}`}
                        className="bankcard_root img py-0 "
                      >
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}/${card?.image}`}
                          alt=""
                          className="credit_image"
                        />
                      </Link>
                    );
                })}
                {/* <Link to="/card-details/1">
                  <BankCard />
                </Link>
                <Link to="/card-details/2">
                  <BankCard color="purple" />
                </Link>
                <Link to="/card-details/3">
                  <BankCard color="black" />
                </Link> */}
              </div>
            </div>
          </div>
        </div>

        {/* CATEGORIES SECTION */}
        <div className="pl-20 pt-20 pb-20">
          <p className="section_heading ">What do you want to do Now ?</p>
          <div className="overflow-x mt-20">
            <CategoryItems />
          </div>
        </div>

        {/* SHOPS SECTION */}
        <Shops />

        {/* DISCOVER SECTION */}
        <div className="pl-20 pb-20 mt-20 ">
          <Discover />
        </div>
        <div className="pl-20 pb-20 pr-20">
          <NewsLetters />
        </div>
      </div>
    </MobileWidthLayout>
  );
};

export default Dashboard;
