import React, { useState } from "react";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import "./CardDetails.css";
import { useParams } from "react-router-dom";
import BankCard from "../../components/BankCards/BankCard";
import InfoIcon from "../../assests/JsxSvgs/infoIcon";
import HomeIcons from "../../assests/JsxSvgs/HomeIcons";
import MessageIcon from "../../assests/JsxSvgs/messageIcon";
import FileIcon from "../../assests/JsxSvgs/FileIcon";
import UserSvgIcon from "../../assests/JsxSvgs/UserSvgIcon";
import InfoPage from "./InfoPage/InfoPage";
import MessagePage from "./MessagePage/MessagePage";
import FilePage from "./FilePage/FilePage";
import UserPage from "./UserPage/UserPage";
import PhoneIcon from "../../assests/JsxSvgs/PhoneIcon";
import ShopsPage from "./HomePage/ShopsPage";
import MobileWidthLayout from "../../components/MobileWidthLoyout/MobileWidthLayout";

let tabsData = [
  {
    id: 1,
    icon: <InfoIcon />,
    color: "sky_blue",
  },
  {
    id: 2,
    icon: <HomeIcons />,
    color: "yellow",
  },
  {
    id: 3,
    icon: <MessageIcon />,
    color: "blue",
  },
  {
    id: 4,
    icon: <FileIcon />,
    color: "purple",
  },
  {
    id: 5,
    icon: <UserSvgIcon />,
    color: "green",
  },
];

const CardDetails = () => {
  const [currentTab, setCurrentTab] = useState(tabsData[0].id);
  const { id } = useParams();
  return (
    <MobileWidthLayout>
      <div className="card_details_page">
        <div className="sticky_top_nav  ">
          <div className="bg_primary">
            <GoBackHeader title="Card Details" />
            <div className=" flex align-center justify-center card_detail_card_cont">
              {id == 1 && <BankCard />}
              {id == 2 && <BankCard color="purple" />}
              {id == 3 && <BankCard color="black" />}
            </div>
          </div>

          <div className="card no_radius ">
            <div className="flex justify-between">
              {tabsData.map((each) => (
                <div
                  onClick={() => setCurrentTab(each.id)}
                  key={each.id}
                  className={`icon_cont active_pill ${
                    each.id == currentTab ? each.color : ""
                  }`}
                >
                  {each.icon}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="card m-20 min_height_detail_card">
          {currentTab == 1 && <InfoPage />}
          {currentTab == 2 && <ShopsPage />}
          {currentTab == 3 && <MessagePage />}
          {currentTab == 4 && <FilePage />}
          {currentTab == 5 && <UserPage />}
        </div>

        <div className="dial_to_sticky icon_cont">
          <PhoneIcon />
        </div>
      </div>
    </MobileWidthLayout>
  );
};

export default CardDetails;
