import React from "react";

const AtmCardIcon = () => {
  return (
    <svg
      width="23"
      height="18"
      viewBox="0 0 23 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2.97916C0 2.18903 0.302901 1.43127 0.842068 0.872575C1.38124 0.313874 2.1125 0 2.875 0H20.125C20.8875 0 21.6188 0.313874 22.1579 0.872575C22.6971 1.43127 23 2.18903 23 2.97916V14.8958C23 15.6859 22.6971 16.4437 22.1579 17.0024C21.6188 17.5611 20.8875 17.8749 20.125 17.8749H2.875C2.1125 17.8749 1.38124 17.5611 0.842068 17.0024C0.302901 16.4437 0 15.6859 0 14.8958V2.97916ZM2.875 1.48958C2.49375 1.48958 2.12812 1.64652 1.85853 1.92587C1.58895 2.20522 1.4375 2.5841 1.4375 2.97916V4.46873H21.5625V2.97916C21.5625 2.5841 21.411 2.20522 21.1415 1.92587C20.8719 1.64652 20.5062 1.48958 20.125 1.48958H2.875ZM21.5625 7.44789H1.4375V14.8958C1.4375 15.2908 1.58895 15.6697 1.85853 15.9491C2.12812 16.2284 2.49375 16.3854 2.875 16.3854H20.125C20.5062 16.3854 20.8719 16.2284 21.1415 15.9491C21.411 15.6697 21.5625 15.2908 21.5625 14.8958V7.44789Z"
        fill="#555555"
      />
      <path
        d="M2.87476 11.9166C2.87476 11.5215 3.02621 11.1426 3.29579 10.8633C3.56537 10.5839 3.93101 10.427 4.31226 10.427H5.74976C6.131 10.427 6.49664 10.5839 6.76622 10.8633C7.03581 11.1426 7.18726 11.5215 7.18726 11.9166V13.4062C7.18726 13.8012 7.03581 14.1801 6.76622 14.4595C6.49664 14.7388 6.131 14.8957 5.74976 14.8957H4.31226C3.93101 14.8957 3.56537 14.7388 3.29579 14.4595C3.02621 14.1801 2.87476 13.8012 2.87476 13.4062V11.9166Z"
        fill="#555555"
      />
    </svg>
  );
};

export default AtmCardIcon;
