export const transactionHistoryData = [
  {
    id: 1,
    order: "01",
    name: "Maybank",
    card: "3333",
    paid: true,
    date: "23 Feb",
  },
  {
    id: 2,
    order: "02",
    name: "Public bank",
    card: "1234",
    paid: false,
    date: "26 Feb",
  },
  {
    id: 3,
    order: "03",
    name: "HSBC bank",
    card: "5648",
    paid: true,
    date: "29 Feb",
  },
  {
    id: 4,
    order: "04",
    name: "Public bank",
    card: "3333",
    paid: true,
    date: "23 Feb",
  },
  {
    id: 5,
    order: "05",
    name: "HSBC bank",
    card: "3596",
    paid: true,
    date: "19 Feb",
  },
  {
    id: 6,
    order: "05",
    name: "HSBC bank",
    card: "3596",
    paid: true,
    date: "19 Feb",
  },
  {
    id: 7,
    order: "05",
    name: "HSBC bank",
    card: "3596",
    paid: true,
    date: "19 Feb",
  },
  {
    id: 8,
    order: "05",
    name: "HSBC bank",
    card: "3596",
    paid: true,
    date: "19 Feb",
  },
];
