import React from "react";
import RectEdit from "../../assests/JsxSvgs/RectEdit";

const CardListItem = ({ name, s_no, card_type_id, image }) => {
  return (
    <div className="card-list-item">
      <div className="flex align-center">
        <p className="s_no">{s_no}</p>
        <img
          src={`${process.env.REACT_APP_BASE_URL}/${image}`}
          alt=""
          className="card_image"
        />
        <div>
          <p className="bankname">{name}</p>
          <p className="card_type">{card_type_id}</p>
        </div>
      </div>
      <RectEdit />
    </div>
  );
};

export default CardListItem;
