import React from "react";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import MobileWidthLayout from "../../components/MobileWidthLoyout/MobileWidthLayout";
import NotificationItem from "./NotificationItem";
import "./Notifcations.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { fetchNotifications } from "../../redux/notificationSlice";
import Loader from "../../components/Loader/Loader";
const Notifications = () => {
  const { notifications, isLoading } = useSelector(
    (store) => store?.notifications
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchNotifications());
  }, []);
  console.log(notifications, "some notification");
  return (
    <MobileWidthLayout>
      <Loader loading={isLoading} />
      <div className="page">
        <div className="bg_primary">
          <GoBackHeader title="Notifications" />
        </div>
        <div className="notification-page p-20">
          <div className="notifications_cont">
            {notifications?.map((each) => (
              <NotificationItem
                date={each?.created_at}
                description={each?.description}
                title="KFC"
              />
            ))}
          </div>
          <div className="flex justify-center">
            <button className="clear_all_btn">Clear All</button>
          </div>
        </div>
      </div>
    </MobileWidthLayout>
  );
};

export default Notifications;
