import React, { useState } from "react";
import "./SearchBar.css";
import { FiSearch } from "react-icons/fi";
import { BsMic } from "react-icons/bs";

const SearchBar = () => {
  const [value, setValue] = useState("");
  return (
    <div className="search_bar_root">
      <div className="input_search">
        <div className="icon_cont">
          <FiSearch size="24" />
        </div>
        <input
          onChange={(e) => setValue(e.target.value)}
          value={value || ""}
          type="text"
          placeholder="Search anything"
        />
        <div className="icon_cont">
          <BsMic size="24" />
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
