import React from "react";

const DollarBonus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="31.25"
      viewBox="0 0 40 31.25"
    >
      <g id="Group_7" data-name="Group 7" transform="translate(0 -0.75)">
        <path
          id="Path_19"
          data-name="Path 19"
          d="M35,4.5H20A3.683,3.683,0,0,0,16.25.75,4.686,4.686,0,0,0,12.5,2.5,4.685,4.685,0,0,0,8.75.75,3.683,3.683,0,0,0,5,4.5a5.015,5.015,0,0,0-5,5V27a5.015,5.015,0,0,0,5,5H35a5.015,5.015,0,0,0,5-5V9.5A5.015,5.015,0,0,0,35,4.5ZM16.25,3.25A1.181,1.181,0,0,1,17.5,4.5,2.507,2.507,0,0,1,15,7H13.75V5.75A2.507,2.507,0,0,1,16.25,3.25ZM17.5,12a1.181,1.181,0,0,1-1.25,1.25,2.507,2.507,0,0,1-2.5-2.5V9.5H15A2.507,2.507,0,0,1,17.5,12Zm-6.25-1.25a2.507,2.507,0,0,1-2.5,2.5A1.181,1.181,0,0,1,7.5,12,2.507,2.507,0,0,1,10,9.5h1.25Zm-2.5-7.5a2.507,2.507,0,0,1,2.5,2.5V7H10A2.507,2.507,0,0,1,7.5,4.5,1.181,1.181,0,0,1,8.75,3.25ZM37.5,27A2.507,2.507,0,0,1,35,29.5H13.75V18.25A1.181,1.181,0,0,0,12.5,17a1.181,1.181,0,0,0-1.25,1.25V29.5H5A2.507,2.507,0,0,1,2.5,27V9.5A2.507,2.507,0,0,1,5,7c.25,0,.375-.125.625-.125A3.912,3.912,0,0,0,6.75,8.251,4.686,4.686,0,0,0,5,12a3.683,3.683,0,0,0,3.75,3.75A4.686,4.686,0,0,0,12.5,14a5.092,5.092,0,0,0,3.75,1.75A3.683,3.683,0,0,0,20,12a4.686,4.686,0,0,0-1.75-3.75,4.9,4.9,0,0,0,1-1.25H35a2.507,2.507,0,0,1,2.5,2.5Z"
          fill="#995eff"
        />
        <path
          id="Path_20"
          data-name="Path 20"
          d="M32.5,17a1.25,1.25,0,1,0,0-2.5H30V13.25a1.25,1.25,0,1,0-2.5,0V14.5H26.25a2.507,2.507,0,0,0-2.5,2.5v1.25a2.507,2.507,0,0,0,2.5,2.5h5V22H25a1.25,1.25,0,1,0,0,2.5h2.5v1.25a1.25,1.25,0,0,0,2.5,0V24.5h1.25a2.507,2.507,0,0,0,2.5-2.5V20.75a2.507,2.507,0,0,0-2.5-2.5h-5V17Z"
          fill="#995eff"
        />
      </g>
    </svg>
  );
};

export default DollarBonus;
