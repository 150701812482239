import React from "react";
import { monthsAgoCalculator } from "../../../components/utilities/reuseFunctions";

const NewsLetter = ({ image, name, created_at, postedBy, postedByName }) => {
  return (
    <div className="new_letter_card mb-20">
      <div className="card_img_cont bg_orange">
        <img
          src={`${process.env.REACT_APP_BASE_URL}/${image}`}
          alt=""
          className="card_image"
        />
      </div>
      <div className="card_text_cont">
        <p className="news_letter_title">{name}</p>
        <div className="flex justify-between">
          <p className="news_posted_by flex align-center">
            <span>
              <img src={postedBy} alt="" />
            </span>
            {postedByName}
          </p>
          <p className="news_posted_at">{monthsAgoCalculator(created_at)}</p>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
