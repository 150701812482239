import React from "react";

const PlainHomeIcon = () => {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.30833 0.954003C9.78173 0.555029 10.3809 0.336212 11 0.336212C11.6191 0.336212 12.2183 0.555029 12.6917 0.954003L20.5667 7.59467C21.1582 8.094 21.5 8.82784 21.5 9.60134V21.0382C21.5 21.5797 21.2849 22.099 20.902 22.4818C20.5191 22.8647 19.9998 23.0798 19.4583 23.0798H15.375C14.8335 23.0798 14.3142 22.8647 13.9313 22.4818C13.5484 22.099 13.3333 21.5797 13.3333 21.0382V14.6215C13.3333 14.5441 13.3026 14.47 13.2479 14.4153C13.1932 14.3606 13.119 14.3298 13.0417 14.3298H8.95833C8.88098 14.3298 8.80679 14.3606 8.75209 14.4153C8.6974 14.47 8.66667 14.5441 8.66667 14.6215V21.0382C8.66667 21.5797 8.45156 22.099 8.06868 22.4818C7.68579 22.8647 7.16648 23.0798 6.625 23.0798H2.54167C2.27355 23.0798 2.00806 23.027 1.76035 22.9244C1.51265 22.8218 1.28758 22.6714 1.09799 22.4818C0.908404 22.2923 0.758016 22.0672 0.655413 21.8195C0.552809 21.5718 0.5 21.3063 0.5 21.0382V9.60134C0.5 8.82784 0.841833 8.094 1.43333 7.59467L9.30833 0.954003ZM11.5635 2.29217C11.4058 2.15938 11.2062 2.08657 11 2.08657C10.7938 2.08657 10.5942 2.15938 10.4365 2.29217L2.5615 8.93167C2.46405 9.0137 2.38568 9.11603 2.33188 9.2315C2.27807 9.34696 2.25013 9.47278 2.25 9.60017V21.037C2.25 21.198 2.38067 21.3287 2.54167 21.3287H6.625C6.70235 21.3287 6.77654 21.2979 6.83124 21.2432C6.88594 21.1885 6.91667 21.1144 6.91667 21.037V14.6203C6.91667 13.4922 7.83133 12.5787 8.95833 12.5787H13.0417C14.1687 12.5787 15.0833 13.4922 15.0833 14.6203V21.037C15.0833 21.198 15.214 21.3287 15.375 21.3287H19.4583C19.5357 21.3287 19.6099 21.2979 19.6646 21.2432C19.7193 21.1885 19.75 21.1144 19.75 21.037V9.60134C19.7499 9.47395 19.7219 9.34813 19.6681 9.23266C19.6143 9.1172 19.536 9.01487 19.4385 8.93284L11.5635 2.29217Z"
        fill="#555555"
      />
    </svg>
  );
};

export default PlainHomeIcon;
