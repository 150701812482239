import React, { useState } from "react";
import { FiChevronDown, FiSearch } from "react-icons/fi";
import "./MessagePage.css";
import ManImage from "../../../assests/Images/man.png";
import { IoEllipsisVertical } from "react-icons/io5";
import CurrentUserMessages from "./CurrentUserMessages";
import OtherUserMessages from "./OtherUserMessages";

const searchDropdownMenus = [
  {
    id: 1,
    label: "Public",
  },
  {
    id: 2,
    label: "Own",
  },
  {
    id: 3,
    label: "Favorite",
  },
];

const MessagePage = () => {
  const [search, setSearch] = useState();
  const [searchType, setSearchType] = useState(searchDropdownMenus[0]);
  return (
    <div className="message_page">
      <div className="search_bar_root flex">
        <div className="input_search">
          <div className="icon_cont">
            <FiSearch size="18" />
          </div>
          <input
            onChange={(e) => setSearch(e.target.value)}
            value={search || ""}
            type="text"
            placeholder="Search anything"
          />
        </div>
        <div className="relative">
          <p className="active_pill blue color_blue drop_menu_label">
            {searchType?.label}
            <span className="chevron_down icon_cont">
              <FiChevronDown size={20} className="color_blue" />
            </span>
          </p>
          <div className="drop_menu_cont absolute">
            <ul className="menu_list">
              {searchDropdownMenus?.map((each) => (
                <li
                  key={each.id}
                  onClick={() => setSearchType(each)}
                  className={`menu_item ${
                    each.id == searchType.id ? "active" : ""
                  }`}
                >
                  {each.label}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="chat_box">
        <div className="session_chat">
          <CurrentUserMessages />
          <OtherUserMessages />
        </div>
        <div className="session_chat">
          <CurrentUserMessages />
          <OtherUserMessages />
        </div>
      </div>
    </div>
  );
};

export default MessagePage;
