import React from "react";

const BellIcon = () => {
  return (
    <svg
      width="24"
      height="27"
      viewBox="0 0 24 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1186 16.8814L20.75 14.5127V11.375C20.7473 9.20661 19.9406 7.11624 18.4861 5.50811C17.0315 3.89998 15.0322 2.88832 12.875 2.66875V0.875H11.125V2.66875C8.96775 2.88832 6.96854 3.89998 5.51395 5.50811C4.05936 7.11624 3.25271 9.20661 3.25 11.375V14.5127L0.881375 16.8814C0.717269 17.0454 0.62505 17.268 0.625 17.5V20.125C0.625 20.3571 0.717187 20.5796 0.881282 20.7437C1.04538 20.9078 1.26794 21 1.5 21H7.625V21.6799C7.60597 22.7899 7.99723 23.868 8.72381 24.7074C9.45039 25.5469 10.4612 26.0887 11.5625 26.229C12.1708 26.2893 12.785 26.2217 13.3655 26.0304C13.9461 25.8391 14.4802 25.5284 14.9335 25.1183C15.3867 24.7082 15.7492 24.2078 15.9974 23.6492C16.2457 23.0906 16.3743 22.4863 16.375 21.875V21H22.5C22.7321 21 22.9546 20.9078 23.1187 20.7437C23.2828 20.5796 23.375 20.3571 23.375 20.125V17.5C23.375 17.268 23.2827 17.0454 23.1186 16.8814ZM14.625 21.875C14.625 22.5712 14.3484 23.2389 13.8562 23.7312C13.3639 24.2234 12.6962 24.5 12 24.5C11.3038 24.5 10.6361 24.2234 10.1438 23.7312C9.65156 23.2389 9.375 22.5712 9.375 21.875V21H14.625V21.875ZM21.625 19.25H2.375V17.8622L4.74362 15.4936C4.90773 15.3296 4.99995 15.107 5 14.875V11.375C5 9.51848 5.7375 7.73801 7.05025 6.42525C8.36301 5.1125 10.1435 4.375 12 4.375C13.8565 4.375 15.637 5.1125 16.9497 6.42525C18.2625 7.73801 19 9.51848 19 11.375V14.875C19 15.107 19.0923 15.3296 19.2564 15.4936L21.625 17.8622V19.25Z"
        fill="#555555"
      />
    </svg>
  );
};

export default BellIcon;
