import React from "react";

const InfoIcon = () => {
  return (
    <svg
      id="Group_1"
      data-name="Group 1"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <path
        id="Path_1"
        data-name="Path 1"
        d="M12.528,6.526A1.528,1.528,0,1,1,11,5,1.528,1.528,0,0,1,12.528,6.526Z"
        fill="#01b3ff"
      />
      <path
        id="Path_2"
        data-name="Path 2"
        d="M12.271,10.712v5.036h0a1.271,1.271,0,1,1-2.542,0V10.712h0a1.271,1.271,0,1,1,2.542,0Z"
        fill="#01b3ff"
      />
      <path
        id="Path_3"
        data-name="Path 3"
        d="M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0Zm6.4,17.4A9.044,9.044,0,1,1,8.664,2.223,9.044,9.044,0,0,1,17.4,17.4Z"
        fill="#01b3ff"
      />
      <path
        id="Path_4"
        data-name="Path 4"
        d="M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0Zm6.4,17.4A9.044,9.044,0,1,1,8.664,2.223,9.044,9.044,0,0,1,17.4,17.4Z"
        fill="#01b3ff"
      />
    </svg>
  );
};

export default InfoIcon;
