import React from "react";

const CoinsIcons = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.644"
      height="39.037"
      viewBox="0 0 41.644 39.037"
    >
      <path
        id="Path_21"
        data-name="Path 21"
        d="M31.463,8.851l-.01-.01h0A18.029,18.029,0,0,0,20.264,3.623,15.844,15.844,0,0,1,33.938,31.339,18.083,18.083,0,0,0,31.463,8.851ZM19.3,12.813v1.094H18.056V12.813a1.068,1.068,0,0,0-.352-.832,1.209,1.209,0,0,0-1.541,0,1.068,1.068,0,0,0-.352.832v1.743a4.024,4.024,0,0,0-2.286,4.98,5.189,5.189,0,0,0,5.152,3.225,3.335,3.335,0,0,1,2.847,1.3,1.623,1.623,0,0,1,.257,1.186,1.974,1.974,0,0,1-.786,1.176,3.963,3.963,0,0,1-3.106.657,2.8,2.8,0,0,1-2.26-1.631,1.121,1.121,0,0,0-1.421-.7h0a1.01,1.01,0,0,0-.719.728,1.746,1.746,0,0,0,.179,1.109,4.938,4.938,0,0,0,2.144,2.134v1.742a1.069,1.069,0,0,0,.352.832,1.209,1.209,0,0,0,1.541,0,1.069,1.069,0,0,0,.352-.832V29.367H19.3v1.094a1.069,1.069,0,0,0,.352.832,1.209,1.209,0,0,0,1.541,0,1.069,1.069,0,0,0,.352-.832V28.72a4.025,4.025,0,0,0,2.287-4.979,5.188,5.188,0,0,0-5.153-3.226,3.334,3.334,0,0,1-2.846-1.3,1.624,1.624,0,0,1-.257-1.186,1.974,1.974,0,0,1,.786-1.176,3.963,3.963,0,0,1,3.106-.657,2.8,2.8,0,0,1,2.26,1.631,1.121,1.121,0,0,0,1.421.7h0a1.01,1.01,0,0,0,.719-.727,1.744,1.744,0,0,0-.178-1.109,4.943,4.943,0,0,0-2.145-2.135V12.813a1.068,1.068,0,0,0-.352-.832,1.209,1.209,0,0,0-1.541,0A1.068,1.068,0,0,0,19.3,12.813ZM36.758,6.241l-.035.035.035-.035a18.063,18.063,0,0,0-23.3-1.92,18.086,18.086,0,0,0,5.214,35.4h.01a18,18,0,0,0,10.541-3.389A18.109,18.109,0,0,0,36.758,6.241Zm-5.729,15.4v.1a12.352,12.352,0,0,1-12.352,12.25,1.068,1.068,0,0,0-.832.352,1.209,1.209,0,0,0,0,1.541,1.068,1.068,0,0,0,.832.352,14.6,14.6,0,0,0,14.6-14.6,1.068,1.068,0,0,0-.352-.832,1.209,1.209,0,0,0-1.541,0A1.068,1.068,0,0,0,31.029,21.637Zm-25.056.832a1.064,1.064,0,0,0,.352-.811h0v-.021A12.352,12.352,0,0,1,18.676,9.285a1.069,1.069,0,0,0,.832-.352,1.209,1.209,0,0,0,0-1.541,1.069,1.069,0,0,0-.832-.352,14.6,14.6,0,0,0-14.6,14.6,1.069,1.069,0,0,0,.352.832,1.209,1.209,0,0,0,1.541,0Zm23.9-12.031.063.063c9.747,9.849,2.919,26.974-11.263,26.974a15.838,15.838,0,1,1,11.2-27.037Z"
        transform="translate(-0.544 -0.734)"
        fill="#2565ff"
        stroke="#2565ff"
        strokeWidth="0.1"
      />
    </svg>
  );
};

export default CoinsIcons;
