import React from "react";

const TimerIcon = () => {
  return (
    <svg
      id="Group_8"
      data-name="Group 8"
      xmlns="http://www.w3.org/2000/svg"
      width="39.354"
      height="43.001"
      viewBox="0 0 39.354 43.001"
    >
      <path
        id="Path_22"
        data-name="Path 22"
        d="M4.526,19a1.5,1.5,0,1,0,2.828,1A15,15,0,1,1,21.5,40a14.725,14.725,0,0,1-7.26-1.884,1.5,1.5,0,0,0-1.48,2.6A17.719,17.719,0,0,0,21.5,43,18,18,0,0,0,23,7.076V3h2.5a1.5,1.5,0,0,0,0-3h-8a1.5,1.5,0,0,0,0,3H20V7.118A17.968,17.968,0,0,0,4.526,19Z"
        fill="#fc8415"
      />
      <path
        id="Path_23"
        data-name="Path 23"
        d="M21.5,16A1.5,1.5,0,0,0,20,17.5v6a1.5,1.5,0,0,0,.83,1.342l4,2h0A1.482,1.482,0,0,0,25.5,27a1.5,1.5,0,0,0,.672-2.842L23,22.573V17.5A1.5,1.5,0,0,0,21.5,16Z"
        fill="#fc8415"
      />
      <path
        id="Path_24"
        data-name="Path 24"
        d="M0,23.5A1.5,1.5,0,0,0,1.5,25h12a1.5,1.5,0,0,0,0-3H1.5A1.5,1.5,0,0,0,0,23.5Z"
        fill="#fc8415"
      />
      <path
        id="Path_25"
        data-name="Path 25"
        d="M15,29.5A1.5,1.5,0,0,0,13.5,28H3.5a1.5,1.5,0,1,0,0,3h10A1.5,1.5,0,0,0,15,29.5Z"
        fill="#fc8415"
      />
      <path
        id="Path_26"
        data-name="Path 26"
        d="M7.5,37h6a1.5,1.5,0,0,0,0-3h-6a1.5,1.5,0,1,0,0,3Z"
        fill="#fc8415"
      />
    </svg>
  );
};

export default TimerIcon;
