import React, { useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import DeleteIcon from "../../../assests/JsxSvgs/DeleteIcon";
import RectEdit from "../../../assests/JsxSvgs/RectEdit";

const FileNote = ({ data, deleteHandler, editHandler }) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div className={`file_note_card  ${isOpen ? "open" : ""}`}>
      <div className="flex header justify-between align-center color_blue">
        <p className="note_title">{data?.title}</p>
        <div
          className="icon_cont"
          onClick={() => {
            setIsOpen((state) => !state);
          }}
        >
          {isOpen ? <FiChevronUp size="25" /> : <FiChevronDown size="25" />}
        </div>
      </div>
      {isOpen && (
        <>
          <div className="seperator"></div>
          <div className="card_detail">
            <p className="note_text">{data?.note}</p>
            <div className="flex justify-between align-center">
              <p className="created_at">{data?.created_at}</p>
              <div className="action_buttons flex">
                <div
                  onClick={() => editHandler(data)}
                  className="color_blue icon_cont mr-20"
                >
                  <RectEdit />
                </div>
                <div
                  onClick={() => deleteHandler(data)}
                  className="color_red icon_cont"
                >
                  <DeleteIcon />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FileNote;
