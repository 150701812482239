import React from "react";
import { useNavigate } from "react-router-dom";
import "./CategoryCard.css";

//color = red || purple || yellow || green || pink || blue
const CategoryCard = ({ id, image, name, color = "red" }) => {
  const navigate = useNavigate();
  const categoryClickHandler = () => {
    navigate(`/restaurant/${id}`);
  };
  return (
    <div
      onClick={categoryClickHandler}
      className={`category_card_root red ${color}`}
    >
      <div className="outer">
        <div className="second_outer">
          <div className="category_icon">
            <img
              src={`${process.env.REACT_APP_BASE_URL}/${image}`}
              alt=""
              className="card_image"
            />
          </div>
          <p className="category_title">{name}</p>
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;
